import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../components/Index.vue";
import About from "../components/about.vue";
import AirConditioning from "../components/AirConditioning.vue";
import Tconlinemarketing from "../components/Tconlinemarketing.vue";
import Termsconditions from "../components/Termsconditions.vue";
import Privacypolicy from "../components/Privacypolicy.vue";
import Progectgallery from "../components/Progectgallery.vue";
import Solarpanelsystem from "../components/Solarpanelsystem.vue";
import Contactus from "../components/Contactus.vue";
import Customerdetails from "../components/Customerdetails.vue";
import confirmation from "../components/Finish.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Smart User",

    component: Index,
  },
  {
    path: "/about",
    name: "About Us",
    component: About,
    // component: () =>
    //   import(/* webpackChunkName: "Home" */ "../components/about.vue"),
  },
  {
    path: "/air-conditioning",
    name: "Air Conditioning",

    component: AirConditioning,
  },
  //T&C ONLINE MARKETING
  {
    path: "/Tconlinemarketing",
    name: "T&C ONLINE MARKETING",
    component: Tconlinemarketing,
  },
  //TERMS & CONDITIONS
  {
    path: "/Termsconditions",
    name: "TERMS & CONDITIONS",
    component: Termsconditions,
  },
  //PRIVACY POLICY
  {
    path: "/privacy-policy",
    name: "PRIVACY POLICY",
    component: Privacypolicy,
  },
  //Progectgallery
  {
    path: "/projects-gallery",
    name: "PROJECT GALLERY",
    component: Progectgallery,
  },
  //Solarpanelsystem
  {
    path: "/solar-panel-system",
    name: "SOLAR PANEL SYSTEM",
    component: Solarpanelsystem,
  },
  //Contactus
  {
    path: "/contact",
    name: "Contact us",
    component: Contactus,
  },
  {
    path: "/Customerdetails",
    name: "Customerdetails",
    component: Customerdetails,
  },
  {
    path: "/confirmation",
    name: "confirmation",
    component: confirmation,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // base: process.env.NODE_ENV === 'production' ? '/coffeeshop/' : '/',
  routes,
});
// router.beforeEach((to, from, next) => {
//     console.log( sessionStorage.getItem("SendSMS")=="Yes"&&sessionStorage.getItem("Picture")=="Yes");

//     if (
//         sessionStorage.getItem("SendSMS")=="Yes"&&sessionStorage.getItem("Picture")=="Yes"
//     ) {

//       next("/checkphoto");
//     } else {
//         next("/Instructions");
//     }
//   });
// 该页面点击该路由，会报错，加上这个就不会报错了
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
