import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {
    // SetBaseCode(state, BaseCode) {
    //   state.BaseCode = BaseCode;
    //   window.sessionStorage.setItem("BaseCode", BaseCode);
    // },
  },
  actions: {},
  modules: {},
});
