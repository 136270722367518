<template>
  <div class="page-section home-page" id="page-content">
    <!--.l-header region -->
    <header class="l-header clearfix corporate-layout">
      <div class="contain-to-grid clearfix sticky">
        <nav class="top-bar" data-topbar>
          <div class="centred-logo-info">
            <ul class="title-area">
              <li class="name">
                <h1>
                  <a href="/" rel="home" title="Smart User">
                    <img src="../assets/img/logo-1.png" alt="Smart User"
                  /></a>
                </h1>
              </li>
              <li class="toggle-topbar menu-icon">
                <a href="#"><span>Menu</span></a>
              </li>
            </ul>
            <div class="address-bar-left">
              <div class="address_bar">
                <div class="address_icon">
                  <img src="../assets/img/phone.png" alt="icon" />
                </div>
                <ul class="bar">
                  <li class="address">Call Us Today</li>
                  <li class="text_add">(03) 8528 1831</li>
                </ul>
              </div>
              <div class="address_bar">
                <div class="address_icon">
                  <img src="../assets/img/email.png" alt="icon" />
                </div>
                <ul class="bar">
                  <li class="address">Email</li>
                  <li class="text_add">info@smartuser.com.au</li>
                </ul>
              </div>
            </div>
            <div class="address-bar-right">
              <div class="address_bar">
                <div class="address_icon">
                  <img src="../assets/img/clock.png" alt="icon" />
                </div>
                <ul class="bar">
                  <li class="address">Work Time</li>
                  <li class="text_add">09.30-17.30</li>
                </ul>
              </div>
              <div class="address_bar">
                <div class="address_icon">
                  <img src="../assets/img/map-marker.png" alt="icon" />
                </div>
                <ul class="bar">
                  <li class="address">Address</li>
                  <li class="text_add">
                    11 Howleys Road, Notting Hill, VIC 3168
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <section class="corporate top-bar-section clearfix">
            <div class="menu-menu-container">
              <ul id="menu-menu" class="menu left">
                <li
                  id="menu-item-6196"
                  class="menu-item menu-item-type-post_type menu-item-object-page color-1"
                >
                  <a href="/about" class="has-icon"
                    ><i class="---- None ---- fa"></i> About Us</a
                  >
                </li>

                <li
                  id="menu-item-6197"
                  class="menu-item menu-item-type-post_type menu-item-object-page color-2"
                >
                  <a href="/air-conditioning" class="has-icon"
                    ><i class="---- None ---- fa"></i> Air Conditioners &#038;
                    Rebate (NEW)</a
                  >
                </li>

                <li
                  id="menu-item-6198"
                  class="menu-item menu-item-type-post_type menu-item-object-page color-3"
                >
                  <a href="/contact" class="has-icon"
                    ><i class="---- None ---- fa"></i> Contact Us</a
                  >
                </li>

                <li
                  id="menu-item-6199"
                  class="menu-item menu-item-type-post_type menu-item-object-page color-4"
                >
                  <a href="/privacy-policy" class="has-icon"
                    ><i class="---- None ---- fa"></i> Privacy Policy</a
                  >
                </li>

                <li
                  id="menu-item-6200"
                  class="menu-item menu-item-type-post_type menu-item-object-page color-5"
                >
                  <a href="/projects-gallery" class="has-icon"
                    ><i class="---- None ---- fa"></i> Project Gallery</a
                  >
                </li>
              </ul>
            </div>
            <div class="request-quote right">
              <a
                href="/Customerdetails"
                class="has-icon"
                >Book Now</a
              >
            </div>
            <!-- <a
              href="../../public/static/file/Solar-retailer-CEC_CERTIFICATE_2022-2023.pdf"
              style="margin-right: 10px"
              ><img
                src="../assets/img/clean-300x120.png"
                width="140"
                class="right"
                alt="Clean Energy Council - Approved Solar Retailer"
                style="margin-right: 10px"
            /></a> -->
          </section>
        </nav>
      </div>
      <!--/.top-Menu -->
    </header>
  </div>
</template>

<script>
export default {
};
</script>

<style></style>
