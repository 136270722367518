import Vue from "vue";
import App from "./App.vue";
import store from "./store";
Vue.config.productionTip = false;
import router from "./router";
import $ from 'jquery';
import axios from "axios";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
Vue.use(ElementUI, { locale });






import Vant from "vant";
import "vant/lib/index.css";
import enUS from "vant/es/locale/lang/en-US";
Vue.use(Vant, { enUS });

Vue.directive("title", {
  inserted: function (el, binding) {
    document.title = el.dataset.title;
  },
});

import "@vant/touch-emulator";

// 全局 vue2 main.js
import vueEsign from "vue-esign";
Vue.use(vueEsign);
Vue.prototype.$eventbus = new Vue();
/* 响应拦截器 */
axios.interceptors.response.use((response) => {
  // console.log(response);
  if (response.data.code == 200) {
    // console.log(response.data);
    let url = response.config.url.split("?")[0];
    let urls = url.split("api")[2];
    // &&window.location.pathname!=="/customermsg"
    // if (urls == "/HPCalculator/SaveNotContainAdditionalPicture") {
    //   Progress()
    // }
  }
  return response;
}

);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
