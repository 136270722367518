<template>
  <div v-title :data-title="$route.name">
    <section class="titlebar">
      <div class="row">
        <h1 id="page-title" class="title text-center">
          Air Conditioners &#038; Rebate (NEW)
        </h1>
      </div>
    </section>
    <!-- content  -->
    <main class="l-main">
      <div class="main row">
        <article>
          <div class="body field clearfix">
            <div class="vc_row wpb_row vc_row-fluid vc_custom_1509557626027">
              <div
                class="wpb_column vc_column_container vc_col-sm-12"
                data-equalizer-watch
              >
                <div class="wpb_wrapper"></div>
              </div>
            </div>
            <div class="vc_row wpb_row vc_row-fluid">
              <div
                class="wpb_column vc_column_container vc_col-sm-12"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <div
                    class="wpb_single_image wpb_content_element vc_align_center"
                  >
                    <figure class="wpb_wrapper vc_figure">
                      <div class="vc_single_image-wrapper vc_box_border_grey">
                        <img
                          width="1920"
                          height="350"
                          src="../../public/static/Img/Cooling-and-Heating-Rebates-Under-The-Victorian-Energy-Upgrades-program-1-e1710816607687.png"
                          class="vc_single_image-img attachment-full"
                          alt=""
                          loading="lazy"
                          srcset="
                            ../../public/static/Img/Cooling-and-Heating-Rebates-Under-The-Victorian-Energy-Upgrades-program-1-e1710816607687.png          1920w,
                            ../../public/static/Img/Cooling-and-Heating-Rebates-Under-The-Victorian-Energy-Upgrades-program-1-e1710816607687-300x55.png    300w,
                            ../../public/static/Img/Cooling-and-Heating-Rebates-Under-The-Victorian-Energy-Upgrades-program-1-e1710816607687-1024x187.png 1024w,
                            ../../public/static/Img/Cooling-and-Heating-Rebates-Under-The-Victorian-Energy-Upgrades-program-1-e1710816607687-768x140.png   768w,
                            ../../public/static/Img/Cooling-and-Heating-Rebates-Under-The-Victorian-Energy-Upgrades-program-1-e1710816607687-1536x280.png 1536w
                          "
                          sizes="(max-width: 1920px) 100vw, 1920px"
                        />
                      </div>
                    </figure>
                  </div>
                  <div class="vc_row wpb_row vc_inner vc_row-fluid">
                    <div
                      class="wpb_column vc_column_container vc_col-sm-12"
                      data-equalizer-watch
                    >
                      <div class="wpb_wrapper">
                        <div
                          class="wpb_single_image wpb_content_element vc_align_center"
                        >
                          <figure class="wpb_wrapper vc_figure">
                            <div
                              class="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="1151"
                                height="618"
                                src=" ../../public/static/Img/2-1-1.jpg"
                                class="vc_single_image-img attachment-full"
                                alt=""
                                loading="lazy"
                                srcset="
                                  ../../public/static/Img/2-1-1.jpg          1151w,
                                  ../../public/static/Img/2-1-1-300x161.jpg   300w,
                                  ../../public/static/Img/2-1-1-1024x550.jpg 1024w,
                                  ../../public/static/Img/2-1-1-768x412.jpg   768w,
                                  ../../public/static/Img/2-1-1-650x350.jpg   650w
                                "
                                sizes="(max-width: 1151px) 100vw, 1151px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="revaldak_empty_space"
                    data-heightmobile="15"
                    data-heighttablet="18"
                    data-heightdesktop="20"
                  ></div>
                </div>
              </div>
            </div>
            <div class="vc_row wpb_row vc_row-fluid">
              <div
                class="wpb_column vc_column_container vc_col-sm-12"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <div class="vc_row wpb_row vc_inner vc_row-fluid">
                    <div
                      class="wpb_column vc_column_container vc_col-sm-12"
                      data-equalizer-watch
                    >
                      <div class="wpb_wrapper">
                        <div
                          class="wpb_single_image wpb_content_element vc_align_center"
                        >
                          <figure class="wpb_wrapper vc_figure">
                            <div
                              class="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="1151"
                                height="814"
                                src=" ../../public/static/Img/2-2.jpg"
                                class="vc_single_image-img attachment-full"
                                alt=""
                                loading="lazy"
                                srcset="
                                  ../../public/static/Img/2-2.jpg          1151w,
                                  ../../public/static/Img/2-2-300x212.jpg   300w,
                                  ../../public/static/Img/2-2-1024x724.jpg 1024w,
                                  ../../public/static/Img/2-2-768x543.jpg   768w,
                                  ../../public/static/Img/2-2-100x70.jpg    100w
                                "
                                sizes="(max-width: 1151px) 100vw, 1151px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="revaldak_empty_space"
                    data-heightmobile="15"
                    data-heighttablet="18"
                    data-heightdesktop="20"
                  ></div>
                </div>
              </div>
            </div>
            <div class="vc_row wpb_row vc_row-fluid">
              <div
                class="wpb_column vc_column_container vc_col-sm-12"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <div class="vc_row wpb_row vc_inner vc_row-fluid">
                    <div
                      class="wpb_column vc_column_container vc_col-sm-12"
                      data-equalizer-watch
                    >
                      <div class="wpb_wrapper">
                        <div
                          class="wpb_single_image wpb_content_element vc_align_center"
                        >
                          <figure class="wpb_wrapper vc_figure">
                            <div
                              class="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="1151"
                                height="628"
                                src=" ../../public/static/Img/2-4.jpg"
                                class="vc_single_image-img attachment-full"
                                alt=""
                                loading="lazy"
                                srcset="
                                  ../../public/static/Img/2-4.jpg          1151w,
                                  ../../public/static/Img/2-4-300x164.jpg   300w,
                                  ../../public/static/Img/2-4-1024x559.jpg 1024w,
                                  ../../public/static/Img/2-4-768x419.jpg   768w
                                "
                                sizes="(max-width: 1151px) 100vw, 1151px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="revaldak_empty_space"
                    data-heightmobile="15"
                    data-heighttablet="18"
                    data-heightdesktop="20"
                  ></div>
                </div>
              </div>
            </div>
            <div class="vc_row wpb_row vc_row-fluid">
              <div
                class="wpb_column vc_column_container vc_col-sm-12"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <div class="vc_row wpb_row vc_inner vc_row-fluid">
                    <div
                      class="wpb_column vc_column_container vc_col-sm-12"
                      data-equalizer-watch
                    >
                      <div class="wpb_wrapper">
                        <div
                          class="wpb_single_image wpb_content_element vc_align_center"
                        >
                          <figure class="wpb_wrapper vc_figure">
                            <div
                              class="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="799"
                                height="929"
                                src=" ../../public/static/Img/2-5-e1710815008625.jpg"
                                class="vc_single_image-img attachment-full"
                                alt=""
                                loading="lazy"
                                srcset="
                                  ../../public/static/Img/2-5-e1710815008625.jpg         799w,
                                  ../../public/static/Img/2-5-e1710815008625-258x300.jpg 258w,
                                  ../../public/static/Img/2-5-e1710815008625-768x893.jpg 768w
                                "
                                sizes="(max-width: 799px) 100vw, 799px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="revaldak_empty_space"
                    data-heightmobile="15"
                    data-heighttablet="18"
                    data-heightdesktop="20"
                  ></div>
                </div>
              </div>
            </div>
            <div class="vc_row wpb_row vc_row-fluid">
              <div
                class="wpb_column vc_column_container vc_col-sm-12"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <div class="vc_row wpb_row vc_inner vc_row-fluid">
                    <div
                      class="wpb_column vc_column_container vc_col-sm-12"
                      data-equalizer-watch
                    >
                      <div class="wpb_wrapper">
                        <div
                          class="wpb_single_image wpb_content_element vc_align_center"
                        >
                          <figure class="wpb_wrapper vc_figure">
                            <div
                              class="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="941"
                                height="1200"
                                src=" ../../public/static/Img/2-6.jpg"
                                class="vc_single_image-img attachment-full"
                                alt=""
                                loading="lazy"
                                srcset="
                                  ../../public/static/Img/2-6.jpg          941w,
                                  ../../public/static/Img/2-6-235x300.jpg  235w,
                                  ../../public/static/Img/2-6-803x1024.jpg 803w,
                                  ../../public/static/Img/2-6-768x979.jpg  768w
                                "
                                sizes="(max-width: 941px) 100vw, 941px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="revaldak_empty_space"
                    data-heightmobile="15"
                    data-heighttablet="18"
                    data-heightdesktop="20"
                  ></div>
                </div>
              </div>
            </div>
            <div class="vc_row wpb_row vc_row-fluid">
              <div
                class="wpb_column vc_column_container vc_col-sm-12"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <div class="vc_row wpb_row vc_inner vc_row-fluid">
                    <div
                      class="wpb_column vc_column_container vc_col-sm-12"
                      data-equalizer-watch
                    >
                      <div class="wpb_wrapper">
                        <div
                          class="wpb_single_image wpb_content_element vc_align_center"
                        >
                          <figure class="wpb_wrapper vc_figure">
                            <div
                              class="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="1151"
                                height="566"
                                src=" ../../public/static/Img/2-7-e1710824662600.jpg"
                                class="vc_single_image-img attachment-full"
                                alt=""
                                loading="lazy"
                                srcset="
                                  ../../public/static/Img/2-7-e1710824662600.jpg          1151w,
                                  ../../public/static/Img/2-7-e1710824662600-300x148.jpg   300w,
                                  ../../public/static/Img/2-7-e1710824662600-1024x504.jpg 1024w,
                                  ../../public/static/Img/2-7-e1710824662600-768x378.jpg   768w
                                "
                                sizes="(max-width: 1151px) 100vw, 1151px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vc_row wpb_row vc_inner vc_row-fluid">
                    <div
                      class="wpb_column vc_column_container vc_col-sm-12"
                      data-equalizer-watch
                    >
                      <div class="wpb_wrapper">
                        <div
                          class="wpb_single_image wpb_content_element vc_align_center"
                        >
                          <figure class="wpb_wrapper vc_figure">
                            <div
                              class="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="1920"
                                height="494"
                                src=" ../../public/static/Img/Untitled-design-9-e1710825036471.png"
                                class="vc_single_image-img attachment-full"
                                alt=""
                                loading="lazy"
                                srcset="
                                  ../../public/static/Img/Untitled-design-9-e1710825036471.png          1920w,
                                  ../../public/static/Img/Untitled-design-9-e1710825036471-300x77.png    300w,
                                  ../../public/static/Img/Untitled-design-9-e1710825036471-1024x263.png 1024w,
                                  ../../public/static/Img/Untitled-design-9-e1710825036471-768x198.png   768w,
                                  ../../public/static/Img/Untitled-design-9-e1710825036471-1536x395.png 1536w
                                "
                                sizes="(max-width: 1920px) 100vw, 1920px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="revaldak_empty_space"
                    data-heightmobile="15"
                    data-heighttablet="18"
                    data-heightdesktop="20"
                  ></div>
                </div>
              </div>
            </div>
            <div class="vc_row wpb_row vc_row-fluid">
              <div
                class="wpb_column vc_column_container vc_col-sm-12"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <div class="vc_row wpb_row vc_inner vc_row-fluid">
                    <div
                      class="wpb_column vc_column_container vc_col-sm-12"
                      data-equalizer-watch
                    >
                      <!-- <div class="wpb_wrapper">
                        <div
                          class="wpb_single_image wpb_content_element vc_align_center"
                        >
                          <figure class="wpb_wrapper vc_figure">
                            <div
                              class="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="778"
                                height="697"
                                src=" ../../public/static/Img/faec95da3c0da8f96413abb4a9eec56.png"
                                class="vc_single_image-img attachment-full"
                                alt=""
                                loading="lazy"
                                srcset="
                                  ../../public/static/Img/faec95da3c0da8f96413abb4a9eec56.png         778w,
                                  ../../public/static/Img/faec95da3c0da8f96413abb4a9eec56-300x269.png 300w,
                                  ../../public/static/Img/faec95da3c0da8f96413abb4a9eec56-768x688.png 768w
                                "
                                sizes="(max-width: 778px) 100vw, 778px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div> -->
                    </div>
                  </div>

                  <div
                    class="revaldak_empty_space"
                    data-heightmobile="15"
                    data-heighttablet="18"
                    data-heightdesktop="20"
                  ></div>
                </div>
              </div>
            </div>
            <div
              data-vc-full-width="true"
              data-vc-full-width-init="false"
              class="vc_row wpb_row vc_row-fluid vc_custom_1627269981297 vc_row-has-fill"
            >
              <div
                class="wpb_column vc_column_container vc_col-sm-9"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <h2
                    style="font-size: 45px; color: #ffffff; text-align: left"
                    class="vc_custom_heading"
                  >
                    <span>Start Saving Today!</span>
                  </h2>
                </div>
              </div>
              <div
                class="wpb_column vc_column_container vc_col-sm-3"
                data-equalizer-watch
              >
                <div class="wpb_wrapper">
                  <div class="vc_btn3-container order-btn vc_btn3-left">
                    <a
                      style="background-color: #ffffff; color: #000000"
                      class="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-round vc_btn3-style-custom"
                      href="https://smartuser.com.au/customerdetails"
                      title="Contact"
                      target="_blank"
                      >Book Now For Free Consultation</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="vc_row-full-width vc_clearfix"></div>
          </div>
        </article>
      </div>
    </main>
  </div>
  <!-- /content  -->
</template>

<script>
export default {};
</script>

<style></style>
