<template>
  <div>
    <div id="Gmaps"></div>
    <remote-js
      jsUrl="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6ocCOSA_6fQiOiMUZAJKKv66sq7yksK8&sensor=false&libraries=places&language=en&sessiontoken=61654819d5215"
      :js-load-call-back="initAutocomplete"
    ></remote-js>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6ocCOSA_6fQiOiMUZAJKKv66sq7yksK8&libraries=places&language=en&callback=initAutocomplete&sessiontoken =61654819d5215"></script>
<script>
// import RemoteJs from "../../api/remote";
import RemoteJs from "./Remote";
export default {
  components: { RemoteJs },
  props: {
    mapData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      autocomplete: "",
      companyauto: "",
      componentForm: {
        // address: "short_name",
        street_number: "long_name",
        route: "long_name",
        locality: "long_name",
        postal_code: "long_name",
        administrative_area_level_2: "long_name",
      },
      form: {
        address: "",
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        postal_code: "short_name",
        // administrative_area_level_1:"short_name",
        administrative_area_level_2: "short_name",
      },
    };
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        // console.log(newValue);
        this.$emit("fun", newValue);
      },
      deep: true,
    },
  },
  methods: {
    initAutocomplete() {
      // Create the autocomplete object, restricting the search to geographical
      // location types.
      this.autocomplete = new google.maps.places.Autocomplete(
        /** @type  {!HTMLInputElement} */ (
          document.getElementById("autocomplete")
        ),
        { types: ["geocode"], componentRestrictions: { country: "au" } }
      );

      // When the user selects an address from the dropdown, populate the address
      // fields in the form.
      this.autocomplete.addListener("place_changed", this.fillInAddress);
    },
    fillInAddress() {
      // Get the place details from the autocomplete object.
      var place = this.autocomplete.getPlace();
      // for (var component in this.componentForm) {
      //   // document.getElementById(component).value = "";
      //   // document.getElementById(component).disabled = false;
      //   // console.log(document.getElementById(component), component);
      //   // this.form[component] = "";
      //   if(component!="city"){
      //     document.getElementById(component).disabled = true;
      //   }
      // }
      // for(let i=0;i<this.componentForm.length;i++){
      //   this.form[i]=
      // }
      // // Get each component of the address from the place details
      // and fill the corresponding field on the form.
      // console.log(place);
      this.form = {
        address: "",
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        postal_code: "short_name",
        // administrative_area_level_1:"short_name",
        administrative_area_level_2: "short_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        // console.log(this.form[addressType]+1,addressType);
        if (this.form[addressType]) {
          var val =
            place.address_components[i][this.componentForm[addressType]];
          // document.getElementById(addressType).value = val;
          this.form[addressType] = val;
        }
      }
      this.form.address = place.formatted_address;
      // console.log(this.form);
    },

    geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy,
          });
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    loadRongJs() {
      // console.log(this.mapData)
      var options = {
        zoom: 15,
        // 1：世界
        // 5：大陆/大陆
        // 10：城市
        // 15：街道
        // 20：建筑物
        center: { lat: this.mapData.latitude, lng: this.mapData.longitude },
        // 动态从调用组件传递经纬度
        disableDefaultUI: false,
        zoomControl: true,
      };
      var map = new google.maps.Map(document.getElementById("Gmaps"), options);
      let marker = new google.maps.Marker({
        position: { lat: this.mapData.latitude, lng: this.mapData.longitude },
        map: map,
        title: "Google Map", // 鼠标悬浮显示
        // 此处的icon为标记的自定义图标
        // icon: 'http://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
        // animation:  google.maps.Animation.DROP,
        width: "20px",
        height: "20px",
      });
      marker.setMap(map);

      // 配置定位点鼠标点击显示内容 本文是父组件传过来的
      var infowindow = new google.maps.InfoWindow({
        content: this.mapData.address,
      });
      marker.addListener("click", function () {
        infowindow.open(map, marker);
      });
    },
  },
};
</script>
<style scoped>
#Gmaps {
  width: 100%;
  height: 28rem;
  border-radius: 5px;
}
</style>
