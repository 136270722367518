<template>
  <div class="info" v-title data-title="Confirmation">
    <div class="cellcopy">
      <el-result
        icon="success"
        title="Successfully submitted !"
        subTitle="We will contact you as soon as possible."
      >
        <!-- <template slot="extra">
          <span style="color: #67c23a; font-size: 17px">
            Please keep your recent electricity bill ready.Show it to the
            installer up on arrival.
          </span>
        </template> -->
      </el-result>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // window.fbq("track", "ViewContent", {
    //   content_type: "Confirmation",
    //   content_name: "Confirmation",
    //   content_ids: ["10002"],
    //   content_category: "Confirmation",
    // });
    window.fbq("track", "CompleteRegistration");
  },
};
</script>

<style>

.cellcopy {
display: grid;
  place-items: center; /* 这将水平和垂直居中内容 */
  height: 100vh; /* 使容器高度占满整个视口高度，根据需要调整 */
  width: 100%; /* 根据需要调整宽度 */
}

</style>
