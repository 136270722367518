<template>
  <div id="app">
    <Header />
    <router-view ></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Footer,
    Header,
  },
  name: "app",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss"></style>
