<template>
  <div v-title :data-title="$route.name">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div ref="myElement" style="background: white">
      <!-- v-if="offsetwidth > 850" -->
      <!-- <section class="titlebar ">
			  <div class="row">
					  <h1 id="page-title" class="title text-center">Project Gallery</h1>
			  </div>
		  </section> -->
      <div>
        <div style="display: flex; justify-content: center">
          <div style="width: 100%; text-align: center; padding: 10px">
            <span style="font-weight: 700; font-size: 35px"
              >Project Gallery</span
            >
            <div
              style="
                position: relative;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                margin-top: 100px;
              "
            >
              <div style="width: 1200px">
                <el-image
                  v-for="(img, index) in picUrlArr"
                  :key="index"
                  :src="img"
                  style="width: 300px; height: 300px"
                  :preview-src-list="[img]"
                ></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="revaldak_empty_space"
        data-heightmobile="110"
        data-heighttablet="60"
        data-heightdesktop="120"
        style="height: 120px"
      ></div>
      <div
        data-vc-full-width="true"
        data-vc-full-width-init="false"
        class="vc_row wpb_row vc_row-fluid vc_custom_1627269981297copy vc_row-has-fill"
      >
        <div
          class="wpb_column vc_column_container vc_col-sm-9"
          data-equalizer-watch=""
        >
          <div class="wpb_wrapper">
            <h2
              style="
                font-size: 45px;
                color: #ffffff;
                text-align: left;
                margin-left: 10%;
              "
              class="vc_custom_heading"
            >
             <!-- href="https://www.smart-user.co/new_app/24"
                onclick="javascript:window.open('https://www.smart-user.co/new_app/24'); return false;" -->
              <a
               
                >Contact Us to Start Saving Today!</a
              >
            </h2>
          </div>
        </div>
        <div
          class="wpb_column vc_column_container vc_col-sm-3"
          data-equalizer-watch=""
        >
          <div class="wpb_wrapper">
            <div class="vc_btn3-container order-btn vc_btn3-left">
              <a
                style="background-color: #ffffff; color: #000000;margin-left: 10%;"
                class="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-round vc_btn3-style-custom"
                href="/Customerdetails"
                title="Contact"
                target="_blank"
                >CONTACT US NOW</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="vc_row-full-width vc_clearfix"></div>
      <!-- <div v-else style="margin-bottom: 20px">
        <div style="display: flex; justify-content: center"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picUrlArr: [
         require("../assets/projectimg/8e02d1415572f3cd092e6b37162bf79.png"),
        require("../assets/projectimg/9bac4a40c6cf3eb7cb75e7ae5e6c2de.png"),
        require("../assets/projectimg/0d7707741d9d6e259c1739f3694e9bb.png"),
        require("../assets/projectimg/97401159bbf3a164f055671fabe85ea.png"),
        require("../assets/projectimg/1.jpg"),
        require("../assets/projectimg/2.png"),
        require("../assets/projectimg/3.jpg"),
        require("../assets/projectimg/4.jpg"),
        require("../assets/projectimg/5.jpg"),
        require("../assets/projectimg/6.png"),
        require("../assets/projectimg/7.jpg"),
        require("../assets/projectimg/8.jpg"),
        require("../assets/projectimg/9.jpg"),
        require("../assets/projectimg/10.jpg"),
        require("../assets/projectimg/11.jpg"),
        require("../assets/projectimg/12.jpg"),
        require("../assets/projectimg/13.jpg"),
        require("../assets/projectimg/14.jpg"),
        require("../assets/projectimg/15.jpg"),
        require("../assets/projectimg/16.jpg"),
        require("../assets/projectimg/17.png"),
        require("../assets/projectimg/18.jpg"),
        require("../assets/projectimg/19.jpg"),
        require("../assets/projectimg/20.jpg"),
        require("../assets/projectimg/21.png"),
        require("../assets/projectimg/22.jpg"),
        require("../assets/projectimg/23.jpg"),
        require("../assets/projectimg/24.jpg"),
        require("../assets/projectimg/25.jpg"),
        require("../assets/projectimg/26.png"),
        require("../assets/projectimg/27.jpeg"),
        require("../assets/projectimg/28.jpeg"),

        require("../assets/projectimg/29.jpg"),
        require("../assets/projectimg/30.jpg"),
        require("../assets/projectimg/31.jpg"),
        require("../assets/projectimg/32.jpeg"),
        require("../assets/projectimg/33.jpeg"),
        require("../assets/projectimg/34.png"),
        require("../assets/projectimg/35.jpeg"),
        require("../assets/projectimg/36.jpeg"),
      ],
      offsetwidth: document.documentElement.clientWidth,
    };
  },
  mounted() {},

  methods: {
    // goinfo() {
    //   // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
    //   this.$emit("fun", "/CustomerDetails");
    // },
  },
};
</script>

<style scoped>
.vc_custom_1627269981297copy {
  /* margin-bottom: -40px !important; */
  padding-top: 50px !important;
  padding-bottom: 40px !important;
  background-color: #84b903 !important;
}
/* .vc_custom_1626061515335 {
  margin-bottom: -40px !important;
  padding-top: 50px !important;
  padding-bottom: 40px !important;
  background-color: #84b903 !important;
} */
/* .special-link {
  text-decoration: underline;
}
a:link {
  color: blue;
} */
/* 已访问的链接 */
/* a:visited {
  color: purple;
} */

/* 鼠标悬浮在链接上 */
/* a:hover {
  color: red;
} */

/* .mt_20 {
  margin-top: 20px;
}
.mt_10 {
  margin-top: 10px;
}
.text_bold {
  font-weight: 700;
}
.pcss {
  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
}
.titlecss {
  font-family: Poppins;
  font-style: normal;
} */
</style>
