import axios from "axios"
//封装请求  get  post

//Get

let get = function (url, parmas, responseType) {
  return new Promise((resolve, reject) => {
    axios({
        method: 'Get',
        url: url,
        // Headers:{'auth':token},
        params: parmas,
        responseType: responseType
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      });
  })
}
let post = function (url, data) {
  return new Promise((resolve, reject) => {
    axios({
        method: 'Post',
        url: url,
        data: data
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      });
  })
}
let put = function (url, data) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

let put_one = function (url, data, responseType) {
  return new Promise((resolve, reject) => {
    axios({
        method: 'Put',
        url: url,
        data: data,
        responseType: responseType
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      });
  })
}

export {
  get,
  post,
  put,
  put_one
}