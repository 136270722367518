<template>
  <div class="home1" v-title data-title="Customer Details">
    <div id="all">
      <el-form
        class="home_form"
        ref="form"
        :label-position="tablewidth >= 700 ? 'left' : 'top'"
        :model="form"
        label-width="180px"
        :rules="rules"
      >
        <div
          style="
            text-align: left;
            border-radius-left-top: 10px;
            border-radius-right-top: 10px;
          "
        >
          <div
            class="home_form_item"
            style="background: rgb(239 240 241); line-height: 30px"
          >
            <div style="text_align: left">
              <div
                style="font-weight: 600; font-size: 17px; padding: 10px 20px"
              >
                <span>Customer Information</span>
              </div>
            </div>
          </div>
          <el-form-item
            style="padding-top: 20px"
            class="home_form_item"
            label="First Name*"
            prop="FirstName"
          >
            <el-input
              class="home_input"
              clearable
              v-model="form.FirstName"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item
          class="home_form_item"
          label="Last Name* "
          prop="LastName"
        >
          <el-input
            class="home_input"
            clearable
            v-model="form.LastName"
          ></el-input>
        </el-form-item>
        <el-form-item class="home_form_item" label="Phone*" prop="Phone">
          <el-input
            class="home_input"
            clearable
            v-model="form.Phone"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label-width="180px"
          class="home_form_item"
          label="Build year of your property"
          align="left"
        >
          <el-select v-model="form.Optional">
            <el-option
              label="2010 Or Earlier"
              value="2010 Or Earlier"
            ></el-option>
            <el-option label="2011 - 2016" value="2011 - 2016"></el-option>
            <el-option label="2017 Or Later" value="2017 Or Later"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item
          class="home_form_item"
          label="Email Address"
          prop="Emailaddress"
        >
          <el-input
            class="home_input"
            auto-complete="new-accounts"
            clearable
            v-model="form.Emailaddress"
          ></el-input>
        </el-form-item> -->
        <!-- 地图地址 -->
        <el-form-item
          class="home_form_item"
          for="title"
          label="Installation Address"
          prop="address"
        >
          <div style="text-align: right">
            <div class="home_input flex">
              <el-input
                clearable
                v-model="form.address"
                name="autocomplete"
                id="autocomplete"
                autocomplete="off"
                @focus="geolocate()"
                required
              ></el-input>
              <span
                @click="showhide"
                style="
                  color: #409eff;
                  width: 80px;
                  cursor: pointer;
                  transform: translateY(10px);
                "
                >{{ showandhide ? "Expand -" : "Expand +" }}</span
              >
            </div>

            <!-- <i
              @click="showhide"
              :class="
                showandhide
                  ? 'el-icon-circle-close'
                  : 'el-icon-circle-plus-outline'
              "
            ></i> -->
          </div>
          <!-- <div style="transform: translateY(-10px)">
            
          </div> -->
        </el-form-item>
        <el-form-item
          v-show="showandhide"
          class="home_form_item"
          label="Unit Number"
          align="left"
        >
          <el-input
            class="home_input"
            autocomplete="off"
            clearable
            v-model="form.subpremise"
            id="subpremise"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-show="showandhide"
          class="home_form_item"
          for="title"
          label="Street Number*"
          prop="street_number"
        >
          <el-input
            class="home_input"
            name="streetNumber"
            id="street_number"
            autocomplete="off"
            required
            v-model="form.street_number"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-show="showandhide"
          class="home_form_item"
          for="title"
          label="Street Name*"
          prop="route"
        >
          <el-input
            class="home_input"
            name="streetName"
            id="route"
            autocomplete="off"
            required
            v-model="form.route"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-show="showandhide"
          class="home_form_item"
          for="title"
          label="Suburb*"
          prop="locality"
        >
          <el-input
            class="home_input"
            name="locality"
            id="locality"
            autocomplete="off"
            required
            v-model="form.locality"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-show="showandhide"
          class="home_form_item"
          for="title"
          label="Postcode*"
          prop="postal_code"
        >
          <el-input
            class="home_input"
            name="postcode"
            id="postal_code"
            autocomplete="off"
            required
            v-model="form.postal_code"
          ></el-input>
        </el-form-item>
        <!-- <div style="text-align: left">

          <el-form-item class="home_form_item" label="Language" align="left">
            <el-select v-model="form.language">
              <el-option
                v-for="item in languageArr"
                :key="item.label"
                :label="item.label"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div> -->

        <!-- product -->
        <div class="home_form_item">
          <el-form-item
            style="padding-bottom: 10px; text-align: left"
            prop="checkList"
          >
            <div>
              <span style="font-weight: 600; font-size: 17px"
                >Please Select Products</span
              >
              <div>
                (Choose products which have not already been installed under
                Victorian Energy Upgrades Program)
              </div>
            </div>
            <!-- :class="tablewidth > 800 ? 'PCWIDTH' : 'H5WIDTH'" -->
            <div>
              <!-- 手机适配 -->
              <div id="checklist">
                <!-- 第一行 -->
                <div>
                  <input
                    type="checkbox"
                    @click="selectpro('MAC')"
                    name="MAC"
                    id="MAC"
                  />
                  <span
                    style="margin-left: 5px"
                    @click="selectpro('MAC')"
                    class="font_16 font_bold"
                    >Option 1 Multi split Air Conditioning</span
                  >
                </div>
                <div
                  class="flex_around mt_30"
                  style="margin-left: 50px; width: 66.667%"
                >
                  <div class="pos_rel">
                    <div>
                      <div>
                        <img
                          style="width: 300px; vertical-align: middle"
                          src="../assets/img/mac.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="radioshow==true">
                  <div class="font_bold">
                    1. Do you have a central gas heating system?
                  </div>
                  <div class="flex mt_10 " >
                       <el-radio-group v-model="opt1Radio" @change="openmessage">
                      <el-radio label="Yes" >Yes</el-radio>
                      <el-radio label="No" >No</el-radio>
                    </el-radio-group>
                    <!-- <label
                        for="YesRadio"
                        onclick="SelectRadio('OneArea','YesRadio')"
                      >
                        <input
                          type="radio"
                          id="YesRadio"
                          name="OneArea"
                          value="Yes"
                        />
                        <span style="cursor: pointer;font-weight:700">Yes</span>
                      </label>

                      <label
                        for="NoRadio"
                        onclick="SelectRadio('OneArea','NoRadio')"
                      >
                        <input
                          type="radio"
                          id="NoRadio"
                          name="OneArea"
                          value="No"
                        />
                        <span style="cursor: pointer">No</span>
                      </label> -->
                  </div>
                  <div class="font_bold mt_30">
                    2. How many storeys are in your house?
                  </div>
                  <div class="flex">
                    <el-radio-group v-model="opt2Radio">
                      <el-radio label="Single Storey">Single storey</el-radio>
                      <el-radio label="Double Storey">Double storey</el-radio>
                    </el-radio-group>

                    <!-- <label for="SingleRadio">
                        <input
                          type="radio"
                          id="SingleRadio"
                          value="Single Storey"
                          name="TwoArea"
                        />
                        <span style="cursor: pointer">Single storey</span>
                      </label>
                      <label for="DoubleRadio">
                        <input
                          type="radio"
                          id="DoubleRadio"
                          value="Double Storey"
                          name="TwoArea"
                        />
                        <span style="cursor: pointer">Double storey</span>
                      </label> -->
                  </div>
                </div>
                <div>
                  <input
                    type="checkbox"
                    @click="selectpro('SP')"
                    name="SP"
                    id="SP"
                  />
                  <span
                    style="margin-left: 5px"
                    @click="selectpro('SP')"
                    class="font_16 font_bold"
                    >Option 2 Solar Panel Package ($0 Upfront)</span
                  >
                </div>
                <div
                  class="flex_around mt_30"
                  style="margin-left: 50px; width: 66.667%"
                >
                  <div class="pos_rel">
                    <div>
                      <img
                        style="width: 300px; vertical-align: middle"
                        src="../assets/img/solar+按摩椅.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <input
                    type="checkbox"
                    @click="selectpro('RHP')"
                    name="RHP"
                    id="RHP"
                  />
                  <span
                    style="margin-left: 5px"
                    @click="selectpro('RHP')"
                    class="font_16 font_bold"
                    >Option 3 Hot Water System</span
                  >
                </div>
                <div
                  class="flex_around mt_30"
                  style="margin-left: 50px; width: 66.667%"
                >
                  <div class="pos_rel">
                    <div>
                      <img
                        style="width: 300px; vertical-align: middle"
                        src="../assets/img/RHP.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <!-- 第二行 -->
                <!-- <div
                  class="flex_around mt_30"
                  style="margin-left: 10px; width: 66.667%"
                >
                  <div class="pos_rel">
                    <div class="iconparent">
                      <div class="icon_div">
                        <img
                          class="icon_form"
                          src="../assets/img/fanseal.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="pos_rel">
                    <div class="iconparent">
                      <div class="icon_div">
                        <img
                          class="icon_form"
                          src="../assets/img/Air.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <input
                    type="radio"
                    @click="selectpro1('option2')"
                    name="options"
                    id="option2"
                  />
                  <span @click="selectpro('option2')" class="font_16 font_bold"
                    >Option 2 - Exhaust Fan Seals + Air Conditioner</span
                  >
                </div> -->
                <!-- 第三行 -->

                <!-- <div
                    class="flex_around mt_30"
                    style="margin-left: 50px; width: 66.667%"
                  >
                    <div class="pos_rel">
                      <div>
                        <img
                          style="
                            width: 400px;
                            height: 350px;
                            vertical-align: middle;
                          "
                          src="../assets/img/空调+产品.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div> -->

                <!-- 第四行 -->
                <!-- <div
                  class="flex_around mt_30"
                  style="margin-left: 15px; width: 33.33%"
                >
                  <div class="pos_rel">
                    <div class="iconparent">
                      <div class="icon_div">
                        <img
                          class="icon_form_mini"
                          src="../assets/img/mac.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <input
                     type="checkbox"
                    @click="selectpro1('option4')"
                    name="options"
                    id="option4"
                  />
                  <span @click="selectpro('option4')" class="font_16 font_bold"
                    >Option 4- Air Conditioner Package</span
                  >
                </div> -->
                <!-- 第五行 -->
                <!-- <div
                  class="flex_around mt_30"
                  style="margin-left: 10px; width: 33.33%"
                >
                  <div class="pos_rel">
                    <div class="iconparent">
                      <div class="icon_div">
                        <img
                          class="icon_form"
                          src="../assets/img/solar.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <input
                    type="checkbox"
                    @click="selectpro1('option5')"
                    name="option5"
                    id="option5"
                  />
                  <span @click="selectpro('option5')" class="font_16 font_bold"
                    >Option 5 - Solar Panel ($0 Upfront)</span
                  >
                </div> -->
              </div>
            </div>
          </el-form-item>
        </div>
        <!-- <el-form-item
          v-if="hasHP"
          style="margin-top: 5px"
          label="Where do you want to install the heat pump hot water system?"
          prop="HPradio"
        >
          <el-radio-group
            v-model="form.HPradio"
            @input="
              form.HPradio === 0 ? (submitbtn = false) : (submitbtn = true)
            "
          >
            <el-radio :label="0">commercial property</el-radio>
            <el-radio :label="1">residential property</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <div class="home_form_item1">
          <div style="margin-top: 10px; padding: 0 25px; text-align: center">
            <!-- <el-checkbox id="checkbox" class="flex" v-model="form.radio">
                You acknowledge that you must provide your recent electricity
                bill to the installer upon arrival.
              </el-checkbox> -->
            <!-- <div style="font-size: 12px; font-weight: 600">
                Submit Your Information for FREE Consultation
              </div> -->
            <!-- submit -->
            <div
              class="font_12 mt_50 font_bold text_center"
              style="padding: 0 20px"
            >
              <p>Please submit your information for FREE Consultation</p>
              <p>(We will call you soon)</p>
            </div>
            <div
              style="background-color: rgb(242, 242, 242); padding-bottom: 20px"
            >
              <!-- <span style="color: red">*</span> -->
              <span>
                *By clicking submit,I state that l have read,understood and
                agree the
                <el-link
                  type="primary"
                  href="/Tconlinemarketing"
                  target="_blank"
                  >terms and conditions</el-link
                >.I consent to share my information with Smart User (accredited
                entity under the VEU program), who will use it in accordance
                with their
                <el-link
                  type="primary"
                  href="/privacy-policy"
                  target="_blank"
                >
                  privacy policy</el-link
                >
                and contact me about current & future government rebate updates.
              </span>
            </div>
             <div class="flex_around"  style="margin-top: 10px;">
            <input
              id="submit"
             @click="onSubmit('form')"
              class="submit home_btn mt_20 mb_20"
              type="button"
              value="Submit"
            />
          </div>
            <!-- <el-button
              :disabled="submitbtn"
              style="margin: 10px 0; width: 200px"
              type="primary"
              @click="onSubmit('form')"
              >Submit</el-button
            > -->
          </div>
        </div>
      </el-form>
<dialog id="showDialog"></dialog>
      <GMaps
        v-show="false"
        class="Gmaps"
        @fun="getinfo"
        ref="child"
        :map-data="mapData"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import GMaps from "@/components/map/Gmaps";
import { get, post } from "../assets/api/apicopy";
export default {
  components: { GMaps },
  data() {
    const notNull = (rule, value, callback) => {
      // console.log(value);
      if (!value) {
        return callback(new Error(`${rule.field} is required `));
      }
      // let reg = /^[A-Za-z0-9,.;?@/s]+$/;
      //   if (value && !reg.test(value)) {
      //     return callback(new Error(`${rule.field} is required `));
      //   }
      // (!(/([\u4e00-\u9fa5]{2,4})/.test(value)) &&
      // /^[?=\x21-\x7eA-Za-z0-9]+[?=\x21-\x7eA-Za-z0-9\s]*[?=\x21-\x7eA-Za-z0-9]+$/.test(value))
      callback();
    };
    const notNull1 = (rule, value, callback) => {
      // console.log(value);
      if (value.length == 0) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const notNull_1 = (rule, value, callback) => {
      if (
        !this.form.route ||
        !this.form.locality ||
        !this.form.street_number ||
        !this.form.postal_code
      ) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const notNullallofaddress = (rule, value, callback) => {
      if (
        !this.form.route &&
        !this.form.locality &&
        !this.form.street_number &&
        !this.form.postal_code &&
        !value
      ) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const notNull2 = (rule, value, callback) => {
      // console.log(value);
      if (
        !value &&
        this.form.checkList.filter((item) => {
          return item === "SH";
        }).length > 0
      ) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const checkphone = (rule, value, callback) => {
      // console.log(value);
      let reg = /^0[3,4][0-9]{8}$/;
      if (!reg.test(value)) {
        return callback(new Error(`${rule.field} is required `));
      }
      if (!value) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    const checkpostcode = (rule, value, callback) => {
      let reg = /^3[0-9]{3}$/;
      // console.log(reg.test(value));

      if (!reg.test(value)) {
        return callback(new Error(`${rule.field} is required `));
      }

      callback();
    };
    const checkpostcode1 = (rule, value, callback) => {
      let reg = /^3[0-9]{3}$/;
      if (!reg.test(this.form.postal_code)) {
        return callback(new Error(`${rule.field} is required `));
      }

      callback();
    };

    const checkemail = (rule, value, callback) => {
      let reg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (value && !reg.test(value)) {
        return callback(new Error(`${rule.field} is required `));
      }
      callback();
    };
    return {
      radioshow:false,
      opt1Radio:"Yes",
      opt2Radio:"Single Storey",
      hasHP: false,
      showandhide: false,
      tablewidth: document.documentElement.clientWidth,
      font: "",
      To_Store_ID: 80,
      ToSalerID: 20648,
      lg: 0, //语言
      postFontSize: "16",
      mapData: {
        latitude: 33.242,
        longitude: 124.12,
      },
      autocomplete: null,
      companyauto: null,
      submitbtn: false,
      // 2010 Or Earlier
      form: {
        HPradio: "",
        radio: false,
        language: "English",
        address: "",
        subpremise: "",
        street_number: "",
        route: "",
        locality: "",
        City: "",
        postal_code: "",
        Optional: "",
        // ToSalesID: "",
        // Dimmer: "",
        // LEDNumber: "",
        // cell_height: "",
        // SHNumber: "",
        checkList: [],
      },
      show_language: false,

      opt_number: [
        {
          name: "1",
          label: "1",
        },
        {
          name: "2",
          label: "2",
        },
        {
          name: "3",
          label: "3",
        },
        {
          name: "3+",
          label: "3+",
        },
      ],
      opt_sales: [],
      languageArr: [
        {
          name: "English",
          label: "English",
        },
        {
          name: "Chinese",
          label: "Chinese",
        },
        {
          name: "Vietnamese",
          label: "Vietnamese",
        },
      ],

      rules: {
        HPradio: [
          {
            required: true,
            message: "Please select",
            trigger: ["change", "blur"],
          },
        ],
        address: [
          {
            validator: notNullallofaddress,
            message: "Please Enter Your Address. ",
            trigger: ["blur"],
          },
          {
            validator: notNull_1,
            message:
              "Please Check Address and Re-enter.  (Enter + Sign for Manual Address Entry)",
            trigger: ["blur"],
          },
          {
            validator: checkpostcode1,
            message: "Your Address Must be in Victoria.",
            trigger: ["change", "blur"],
          },
        ],
        checkList: [
          {
            validator: notNull1,
            message: "Please Select Products",
            trigger: ["change", "blur"],
          },
        ],
        FirstName: [
          {
            validator: notNull,
            message: "Please Enter First Name",
            trigger: ["change", "blur"],
          },
        ],
        LastName: [
          {
            validator: notNull,
            message: "Please Enter Last Name",
            trigger: ["change", "blur"],
          },
        ],
        Phone: [
          {
            validator: notNull,
            len: 10,
            message: "Please Enter Your Mobile Number",
            trigger: ["change", "blur"],
          },
          {
            validator: checkphone,
            // pattern: /^0[3,4][0-9]{8}$/,
            len: 10,
            message: "Please Enter Your Correct Mobile Number",
            trigger: ["change", "blur"],
          },
        ],
        Emailaddress: [
          // {
          //   validator: notNull,
          //   message: "Please Enter Your Email Address",
          //   trigger: ["change", "blur"],
          // },
          {
            validator: checkemail,

            message: "Please Enter Your Correct Email Address",
            trigger: ["change", "blur"],
          },
        ],

        street_number: [
          {
            validator: notNull,
            message: "Please Enter Your Street Number",
            trigger: ["change", "blur"],
          },
        ],
        route: [
          {
            validator: notNull,
            message: "Please Enter Your Street Name",
            trigger: ["change", "blur"],
          },
        ],
        locality: [
          {
            validator: notNull,
            message: "Please Enter Your Suburb",
            trigger: ["change", "blur"],
          },
        ],
        postal_code: [
          {
            validator: notNull,
            // pattern: /^3[0-9]{3}$/,
            message: "Please Enter Your Post Code",
            trigger: ["change", "blur"],
          },
          {
            validator: checkpostcode,
            // pattern: /^3[0-9]{3}$/,
            message: "Your Address Must be in Victoria.",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler: function (newV, oldV) {
        // console.log(this.form);
        if (newV.FreeLED === 1) {
          oldV.Dimmer = null;
          oldV.LEDNumber = null;
          oldV.cell_height = null;
        }
      },
    },
    tablewidth: function (val) {
      if (!this.timer) {
        this.tablewidth = val;
        // console.log(val)
        // this.changeheight(val);
        this.timer = true;
        let that = this;
        setTimeout(function () {
          //频繁触发 resize 函数，会导致页面很卡
          that.timer = false;
        }, 100);
      }
    },
  },
  mounted() {
    // this.To_Store_ID = this.$route.query.To_Store_ID;
    // this.ToSalerID = this.$route.query.ToSalerID;
    // this.getsale();/
    // fbq('init', '319494653477175');
    // console.log(window);
    // window.fbq("track", "ViewContent", {
    //   content_type: "CustomerDetails",
    //   content_ids: ["10001"],
    //   content_name: "CustomerDetails",
    //   content_category: "CustomerDetails",
    // });
    // let checkwidth = document.documentElement.clientWidth;
    // let checklistdom = document.getElementById("checklist");
    // let checklistdom_1 = document.getElementById("checklist1");
    // if (checkwidth > 800 || checkwidth == 0) {
    //   checklistdom.style.cssText = "display:none";
    //   checklistdom_1.style.cssText = "display:block";
    // } else {
    //   checklistdom.style.cssText = "display:block";
    //   checklistdom_1.style.cssText = "display:none";
    // }
    if (this.$route.params.pro == "H5SP") {
      $("#SP").prop("checked", true);
      this.selectpro("SP");
    } else if (this.$route.params.pro == "PCSP") {
      $("#SP").prop("checked", true);
      this.selectpro("SP");
    } else if (this.$route.params.pro == "H5HOME") {
      $("#MAC").prop("checked", true);
      this.selectpro("MAC");
    } else if (this.$route.params.pro == "PCHOME") {
      $("#MAC").prop("checked", true);
      this.selectpro("MAC");
    } else if (this.$route.params.pro == "H5MAC") {
      $("#MAC").prop("checked", true);
      this.selectpro("MAC");
    } else if (this.$route.params.pro == "PCMAC") {
      $("#MAC").prop("checked", true);
      this.selectpro("MAC");
    }
    let that = this;
    window.addEventListener("resize", () => {
      window.tablewidth = document.documentElement.clientWidth;
      that.tablewidth = window.tablewidth;

      // let timer = setInterval(() => {
      //   checkwidth = document.documentElement.clientWidth;
      //   if (checkwidth > 800 || checkwidth == 0) {
      //     checklistdom.style.cssText = "display:none";
      //     checklistdom_1.style.cssText = "display:block";
      //   } else {
      //     checklistdom.style.cssText = "display:block";
      //     checklistdom_1.style.cssText = "display:none";
      //   }

      //   // console.log(checkwidth);
      //   clearInterval(timer);
      // }, 200);
    });
  },
  methods: {
    openmessage(value){
      let submit = document.getElementById("submit");
      if(value=="No"){
        submit.setAttribute("disabled", true);
         submit.style.cssText = "background-color: #999";
this.$message.warning("Unfortunately, your property does not meet the eligibility criteria outlined in Activity 6 (vii) for the VEU Program rebate.")

      }else{
         submit.removeAttribute("disabled");
        submit.style.cssText = "background-color: #409eff";
      }
    },
    // SelectRadio(type, name) {
    //   let submit = document.getElementById("submit");
    //   var dialog = document.getElementsByTagName("dialog")[0];
    //   if (name === "NoRadio") {
    //     submit.setAttribute("disabled", true);
    //     submit.style.cssText = "background-color: #999";
    //     $("#showDialog").append(
    //       "<p>Unfortunately, your property does not meet the eligibility criteria outlined in Activity 6 (vii) for the VEU Program rebate.</p>"
    //     );
    //     dialog.showModal();
    //     let timer = setInterval(() => {
    //       clearInterval(timer);
    //       //关闭弹框
    //       dialog.close();
    //       $("#showDialog").children().remove();
    //     }, 3000);
    //   } else {
    //     $("#showDialog").children().remove();
    //     submit.removeAttribute("disabled");
    //     submit.style.cssText = "background-color: #409eff";
    //   }
    // },
    selectpro1(type) {
      if (type === "option1" || type === "option6") {
        if (document.getElementById(type).checked) {
          if (this.form.checkList.indexOf("SP") == -1) {
            this.form.checkList.push("SP");
          }
        } else if (document.getElementById(type).checked == false) {
          this.form.checkList.splice(this.form.checkList.indexOf("SP"), 1);
        }
      } else if (type === "option3" || type === "option8") {
        if (document.getElementById(type).checked) {
          if (
            this.form.checkList.indexOf("MAC") == -1 &&
            this.form.checkList.indexOf("SH") == -1 &&
            this.form.checkList.indexOf("FS") == -1 &&
            this.form.checkList.indexOf("DS") == -1 &&
            this.form.checkList.indexOf("CS") == -1 &&
            this.form.checkList.indexOf("IHD") == -1
          ) {
            this.form.checkList.push("MAC");
            this.form.checkList.push("CS");
            this.form.checkList.push("DS");
            this.form.checkList.push("FS");
            this.form.checkList.push("SH");
            this.form.checkList.push("IHD");
          }
        } else if (document.getElementById(type).checked == false) {
          this.form.checkList.splice(this.form.checkList.indexOf("MAC"), 1);
          this.form.checkList.splice(this.form.checkList.indexOf("CS"), 1);
          this.form.checkList.splice(this.form.checkList.indexOf("FS"), 1);
          this.form.checkList.splice(this.form.checkList.indexOf("DS"), 1);
          this.form.checkList.splice(this.form.checkList.indexOf("IHD"), 1);
          this.form.checkList.splice(this.form.checkList.indexOf("SH"), 1);
        }
      }
      console.log(this.form.checkList);
    },
    selectpro(type) {
      let res =
        this.form.checkList.filter((item) => {
          return item === type;
        }).length > 0;
      if (res) {
        this.form.checkList.splice(this.form.checkList.indexOf(type), 1);
        document.getElementById(type).parentNode.childNodes[1].style.cssText =
          "color:#000";
        document.getElementById(type).checked = false;
      } else {
        this.form.checkList.push(type);
        document.getElementById(type).parentNode.childNodes[1].style.cssText =
          "color:#409eff";
        document.getElementById(type).checked = true;
      }
      console.log(this.form.checkList);
    
       if (this.form.checkList.length > 0 &&this.form.checkList.some(item => item.includes('MAC'))) {
         this.radioshow=true
      } else {
         this.radioshow=false
      }
    },

    // selectpro(type) {
    //   if (
    //     type === "option1" ||
    //     type === "option2" ||
    //     type === "option3" ||
    //     type === "option6" ||
    //     type === "option7" ||
    //     type === "option8"
    //   ) {
    //     let optionarr = [
    //       "option1",
    //       "option2",
    //       "option3",
    //       "option6",
    //       "option7",
    //       "option8",
    //     ];
    //     optionarr.forEach((item) => {
    //       document.getElementById(item).parentNode.childNodes[1].style.cssText =
    //         "color: #000";
    //       document.getElementById(item).checked = false;
    //       if (item === type) {
    //         // console.log(item, type);
    //         document.getElementById(
    //           type
    //         ).parentNode.childNodes[1].style.cssText = "color:#409eff";
    //         document.getElementById(type).checked = true;
    //       }
    //     });
    //     this.form.checkList=[];
    //     if (
    //       document.getElementById("option4").checked ||
    //       document.getElementById("option9").checked
    //     ) {
    //        this.form.checkList.push("MAC");
    //     }
    //     if (
    //       document.getElementById("option5").checked ||
    //       document.getElementById("option10").checked
    //     ) {
    //        this.form.checkList.push("SP");
    //     }
    //      this.form.checkList.push("AC");
    //     if (type === "option1" || type === "option6") {
    //        this.form.checkList.push("SH");
    //     } else if (type === "option2" || type === "option7") {
    //        this.form.checkList.push("FS");
    //     } else if (type === "option3" || type === "option8") {
    //        this.form.checkList.push("FS");
    //       this.form.checkList.push("SH");
    //        this.form.checkList.push("IHD");
    //        this.form.checkList.push("CS");
    //     }
    //   } else if (type === "option4" || type === "option9") {
    //     if (!document.getElementById(type).checked) {
    //       document.getElementById(type).checked = true;
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#409eff";
    //       if ( this.form.checkList.indexOf("MAC") == -1) {
    //          this.form.checkList.push("MAC");
    //       }
    //     } else {
    //       document.getElementById(type).checked = false;

    //        this.form.checkList.splice( this.form.checkList.indexOf("MAC"), 1);
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#000";
    //     }
    //   } else if (type === "option5" || type === "option10") {
    //     if (!document.getElementById(type).checked) {
    //       document.getElementById(type).checked = true;
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#409eff";
    //       if ( this.form.checkList.indexOf("SP") == -1) {
    //          this.form.checkList.push("SP");
    //       }
    //     } else {
    //       document.getElementById(type).checked = false;

    //        this.form.checkList.splice( this.form.checkList.indexOf("SP"), 1);
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#000";
    //     }
    //   }
    //   console.log( this.form.checkList);
    // },
    // selectpro(type) {
    //   if (type !== "option5" && type !== "option10") {
    //     let optionarr = [
    //       "option1",
    //       "option2",
    //       "option3",
    //       "option4",
    //       "option6",
    //       "option7",
    //       "option8",
    //       "option9",
    //     ];
    //     optionarr.forEach((item) => {
    //       document.getElementById(item).parentNode.childNodes[1].style.cssText =
    //         "color: #000";
    //       document.getElementById(item).checked = false;
    //       if (item === type) {
    //         // console.log(item, type);
    //         document.getElementById(
    //           type
    //         ).parentNode.childNodes[1].style.cssText = "color:#409eff";
    //         document.getElementById(type).checked = true;
    //       }
    //     });
    //   }
    //   if (
    //     type === "option1" ||
    //     type === "option2" ||
    //     type === "option3" ||
    //     type === "option6" ||
    //     type === "option7" ||
    //     type === "option8"
    //   ) {
    //     if (
    //       !document.getElementById("option5").checked &&
    //       !document.getElementById("option10").checked
    //     ) {
    //       this.form.checkList = [];
    //     } else {
    //       this.form.checkList = ["SP"];
    //     }
    //     this.form.checkList.push("AC");
    //     if (type === "option1" || type === "option6") {
    //       this.form.checkList.push("SH");
    //     } else if (type === "option2" || type === "option7") {
    //       this.form.checkList.push("FS");
    //     } else if (type === "option3" || type === "option8") {
    //       this.form.checkList.push("FS");
    //       this.form.checkList.push("SH");
    //       this.form.checkList.push("IHD");
    //       this.form.checkList.push("CS");
    //     }
    //     this.hasHP = false;
    //   } else if (type === "option4" || type === "option9") {
    //     this.hasHP = true;

    //     if (
    //       !document.getElementById("option5").checked &&
    //       !document.getElementById("option10").checked
    //     ) {
    //       this.form.checkList = [];
    //     } else {
    //       this.form.checkList = ["SP"];
    //     }
    //     this.form.checkList.push("HP");
    //   } else {
    //     // console.log(type);

    //     this.hasHP = false;
    //     if (!document.getElementById(type).checked) {
    //       document.getElementById(type).checked = true;
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#409eff";
    //       if (this.form.checkList.indexOf("SP") == -1) {
    //         this.form.checkList.push("SP");
    //       }
    //     } else {
    //       document.getElementById(type).checked = false;

    //       this.form.checkList.splice(this.form.checkList.indexOf("SP"), 1);
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#000";
    //     }
    //   }
    //   console.log(this.form.checkList);
    // },
    //  selectpro1(type) {
    //   // console.log(type, document.getElementById(type).checked);
    //   if (
    //     type === "option1" ||
    //     type === "option2" ||
    //     type === "option3" ||
    //     type === "option6" ||
    //     type === "option7" ||
    //     type === "option8"
    //   ) {
    //     let optionarr=[
    //        "option1",
    //       "option2",
    //       "option3",
    //       "option6",
    //       "option7",
    //       "option8",
    //     ]
    //     optionarr.forEach((item) => {
    //       document.getElementById(item).parentNode.childNodes[1].style.cssText =
    //         "color: #000";
    //       document.getElementById(item).checked = false;
    //       if (item === type) {
    //         // console.log(item, type);
    //         document.getElementById(
    //           type
    //         ).parentNode.childNodes[1].style.cssText = "color:#409eff";
    //         document.getElementById(type).checked = true;
    //       }
    //     });
    //     this.form.checkList = [];
    //     if (
    //       document.getElementById("option4").checked ||
    //       document.getElementById("option9").checked
    //     ) {
    //       this.form.checkList.push("MAC");
    //     }
    //     if (
    //       document.getElementById("option5").checked ||
    //       document.getElementById("option5").checked
    //     ) {
    //       this.form.checkList.push("SP");
    //     }
    //     this.form.checkList.push("AC");
    //     if (type === "option1" || type === "option6") {
    //       this.form.checkList.push("SH");
    //     } else if (type === "option2" || type === "option7") {
    //       this.form.checkList.push("FS");
    //     } else if (type === "option3" || type === "option8") {
    //       this.form.checkList.push("FS");
    //       this.form.checkList.push("SH");
    //       this.form.checkList.push("IHD");
    //       this.form.checkList.push("CS");
    //     }
    //   } else if (type === "option4" || type === "option9") {
    //     if (document.getElementById(type).checked) {
    //       // document.getElementById(type).checked = true;
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#409eff";
    //       if (this.form.checkList.indexOf("MAC") == -1) {
    //         this.form.checkList.push("MAC");
    //       }
    //     } else {
    //       this.form.checkList.splice(this.form.checkList.indexOf("MAC"), 1);
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#000";
    //     }
    //   } else if (type === "option5" || type === "option10") {
    //     if (document.getElementById(type).checked) {
    //       // document.getElementById(type).checked = true;
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#409eff";
    //       if (this.form.checkList.indexOf("SP") == -1) {
    //         this.form.checkList.push("SP");
    //       }
    //     } else {
    //       this.form.checkList.splice(this.form.checkList.indexOf("SP"), 1);
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#000";
    //     }
    //   }
    //   console.log(this.form.checkList);
    // },
    // selectpro1(type) {
    //   if (type !== "option5" && type !== "option10") {
    //     let optionarr = [
    //       "option1",
    //       "option2",
    //       "option3",
    //       "option4",
    //       "option6",
    //       "option7",
    //       "option8",
    //       "option9",
    //     ];
    //     optionarr.forEach((item) => {
    //       document.getElementById(item).parentNode.childNodes[1].style.cssText =
    //         "color: #000";
    //       document.getElementById(item).checked = false;
    //       if (item === type) {
    //         // console.log(item, type);
    //         document.getElementById(
    //           type
    //         ).parentNode.childNodes[1].style.cssText = "color:#409eff";
    //         document.getElementById(type).checked = true;
    //       }
    //     });
    //   }
    //   // console.log(type, document.getElementById(type).checked);
    //   if (
    //     type === "option1" ||
    //     type === "option2" ||
    //     type === "option3" ||
    //     type === "option6" ||
    //     type === "option7" ||
    //     type === "option8"
    //   ) {
    //     if (
    //       !document.getElementById("option5").checked &&
    //       !document.getElementById("option10").checked
    //     ) {
    //       this.form.checkList = [];
    //     } else {
    //       this.form.checkList = ["SP"];
    //     }
    //     this.form.checkList.push("AC");
    //     if (type === "option1" || type === "option6") {
    //       this.form.checkList.push("SH");
    //     } else if (type === "option2" || type === "option7") {
    //       this.form.checkList.push("FS");
    //     } else if (type === "option3" || type === "option8") {
    //       this.form.checkList.push("FS");
    //       this.form.checkList.push("SH");
    //       this.form.checkList.push("IHD");
    //       this.form.checkList.push("CS");
    //     }
    //   } else if (type === "option4" || type === "option9") {
    //     if (
    //       !document.getElementById("option5").checked &&
    //       !document.getElementById("option10").checked
    //     ) {
    //       this.form.checkList = [];
    //     } else {
    //       this.form.checkList = ["SP"];
    //     }
    //     this.form.checkList.push("HP");
    //   } else {
    //     // console.log(document.getElementById(type).checked);
    //     if (document.getElementById(type).checked) {
    //       // document.getElementById(type).checked = true;
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#409eff";
    //       if (this.form.checkList.indexOf("SP") == -1) {
    //         this.form.checkList.push("SP");
    //       }

    //       // document.getElementById(type).checked = false;
    //     } else {
    //       this.form.checkList.splice(this.form.checkList.indexOf("SP"), 1);
    //       document.getElementById(type).parentNode.childNodes[1].style.cssText =
    //         "color:#000";
    //     }
    //   }
    //   console.log(this.form.checkList);
    // },

    showhide() {
      this.showandhide = !this.showandhide;
    },
    // selectpro(type) {
    //   let res =
    //     this.form.checkList.filter((item) => {
    //       return item === type;
    //     }).length > 0;
    //   if (res) {
    //     this.form.checkList.splice(this.form.checkList.indexOf(type), 1);
    //   } else {
    //     this.form.checkList.push(type);
    //   }
    // },

    //获取销售下拉
    getsale() {
      get(
        "/api/api/QR_CustomerOrders/GetTodayClockSales?ToStoreID=" +
          this.To_Store_ID
      ).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.opt_sales = res.data.data;
        }
      });
    },

    uplg(type) {
      // console.log(type);
      this.lg = type;
      if (this.lg === 0) {
        this.languageArr = [
          {
            name: "English",
            label: "English",
          },
          {
            name: "Chinese",
            label: "Chinese",
          },
          {
            name: "Vietnamese",
            label: "Vietnamese",
          },
        ];
      } else {
        this.languageArr = [
          {
            name: "English",
            label: "英文",
          },
          {
            name: "Chinese",
            label: "中文",
          },
          {
            name: "Vietnamese",
            label: "Vietnamese",
          },
        ];
      }
    },

    onSubmit() {
      // if(this.form.checkList.){}
      let data = {
        To_Store_ID:52,
        FirstName: this.form.FirstName,
        LastName: this.form.LastName,
        Phone: this.form.Phone,
        Optional: "",
        Centralgasheatling: this.opt1Radio,
        Storey: this.opt2Radio,
        // Emailaddress: this.form.Emailaddress,
        Address: this.form.address,
        Unit_Number: this.form.subpremise,
        Street_Number: this.form.street_number,
        Street_Name: this.form.route,
        Suburb: this.form.locality,
        City: this.form.City,
        PostCode: this.form.postal_code,
       Language: "English",
       ToSalesID: 20267,
        Pro_SH: 0,
        Pro_IHD: 0,
        Pro_DS: 0,
        Pro_CS: 0,
        Pro_LED: 0,
        Pro_FS: 0,
        Pro_AC: 0,
        Pro_SP: 0,
        Pro_HP: 0,
        Pro_MAC: 0,
        Pro_RHP:0
        // Dimmer: this.form.Dimmer,
        // LEDNumber: this.form.LEDNumber,
        // CeilingHeight: this.form.cell_height,
        // SHNumber: this.form.SHNumber,
        // FreeLED: this.form.FreeLED,
      };
      // console.log(this.form.checkList);
      if (this.form.checkList.length > 0) {
        // let result =
        //   this.form.checkList.filter((item) => {
        //     return item === "SH";
        //   }).length > 0;
        // if (result) {
        //   data.Pro_SH = this.form.LEDNumber;
        // }
        this.form.checkList.forEach((item) => {
          if (item === "IHD") {
            data.Pro_IHD = 1;
          }
          if (item === "SH") {
            data.Pro_SH = 1;
          }
          if (item === "DS") {
            data.Pro_DS = 1;
          }
          if (item === "CS") {
            data.Pro_CS = 1;
          }
          if (item === "LED") {
            data.Pro_LED = 1;
          }
          if (item === "FS") {
            data.Pro_FS = 1;
          }
          if (item === "AC") {
            data.Pro_AC = 1;
          }
          if (item === "MAC") {
            data.Pro_MAC = 1;
          }
          if (item === "SP") {
            data.Pro_SP = 1;
          }
          if (item === "RHP") {
            data.Pro_RHP = 1;
          }
        });
      }
     
      // window.location.href = "http://www.baidu.com";
      console.log(data);
      this.$refs.form.validate((valid, obj) => {
        // console.log(Object.keys(obj).length);
        if (valid) {
      //      if (this.form.checkList.length==0) {
      //   this.$message({
      //     message: "Please Select Products.",
      //     type: "warning",
      //     duration: 2500,
      //   });
      //   return;
      // }
          let submit = document.getElementById("submit");
        submit.setAttribute("disabled", true);
         submit.style.cssText = "background-color: #999";
          // console.log(data);
          post("/api/api/QR_CustomerOrders/AddOrder", data).then((res) => {
            // if(res.data){}
            // console.log(res);
            if (res.data.code == 201) {
              this.$router.push("/confirmation");
            } else {
             submit.removeAttribute("disabled");
        submit.style.cssText = "background-color: #409eff";
              this.$message({
                type: "error",
                message: "error submit",
                duration: 2500,
              });
            }
          });
        } else {
          // console.log(obj);
          let msg = [];
          const h = this.$createElement;
          if (
            !this.form.route &&
            !this.form.locality &&
            !this.form.street_number &&
            !this.form.postal_code &&
            !this.form.address
          ) {
            delete obj.postal_code;
            delete obj.route;
            delete obj.locality;
            delete obj.street_number;
            for (let key in obj) {
              // console.log(obj[key]);
              msg.push(h("p", null, obj[key][0].message));
            }
          } else {
            for (let key in obj) {
              // console.log(obj[key]);
              msg.push(h("p", null, obj[key][0].message));
            }
          }

          this.$message({
            type: "warning",
            message: h("div", null, msg),
            duration: 2500,
          });
          if (
            !this.form.route ||
            !this.form.locality ||
            !this.form.street_number ||
            !this.form.postal_code
          ) {
            this.showandhide = true;
          }
        }
      });
    },
    msginfo(type, info, data) {},

    getinfo(data) {
      console.log(data);
      this.form.address = data.address;
      if (!data.locality) {
        this.form.locality = "";
      } else if (data.locality !== "long_name") {
        this.form.locality = data.locality;
      }

      if (!data.route) {
        this.form.route = "";
      } else if (data.route !== "long_name") {
        this.form.route = data.route;
      }

      if (!data.street_number) {
        this.form.street_number = "";
      } else if (data.street_number !== "short_name") {
        this.form.street_number = data.street_number;
      }

      if (!data.postal_code) {
        this.form.postal_code = "";
      } else if (data.postal_code !== "short_name") {
        this.form.postal_code = data.postal_code;
      }
      if (!data.administrative_area_level_2) {
        this.form.City = "";
      } else if (data.administrative_area_level_2 !== "short_name") {
        this.form.City = data.administrative_area_level_2;
      }
      this.form.City = data.administrative_area_level_2;

      // console.log(data);
    },
    geolocate() {
      this.$refs.child.geolocate;
    },
    initAutocomplete() {
      this.$refs.child.initAutocomplete;
    },
    fillInAddress() {
      this.$refs.child.fillInAddress;
    },
  },
};
</script>

<style>
.PCWIDTH {
  padding-right: 100px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.H5WIDTH {
  margin-top: 20px;
}
/* .tm{
  opacity:0;
} */
/* .el-button {
  font-size: 12px;
  padding: 12px 0;
} */
.float {
  float: left;
  top: 0;
}
.el-message--warning .el-message__content {
  color: #e6a23c;
  font-size: 14px;
}
.el-message p {
  color: #e6a23c;
}
.flex_around {
  display: flex;
  justify-content: space-around;
}
.flex_left {
  display: flex;
  justify-content: left;
}
.iconparent {
  width: 82px;
}
.w_100 {
  width: 100%;
}
.imgbox {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.form_item {
  /* font-weight: 600; */
  font-size: 14px;
  padding: 10px 20px;
  /*color: rgb(97, 97, 97);*/
}
.icon_form {
  vertical-align: middle;
  width: 82px;
  height: 82px;
}
.icon_div {
  /* width: 2px;
  height: 82px; */
  /* display: flex;
  flex-direction: column;
  justify-items: center; */
  text-align: center;
  vertical-align: middle;
  /* border: 1px solid #dbdbdb;
  background-color: rgb(255, 255, 255);
  border-radius: 5px; */
  overflow: hidden;
}
.icon_div1 {
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #dbdbdb;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  overflow: hidden;
}
.iconparent {
  width: 84px;
  color: rgb(97, 97, 97);
  font-size: 14px;
  text-align: center;
}
.icon_form_mini {
  vertical-align: middle;
  width: 100%;
}
.iconparent1 {
  width: 52px;
  color: rgb(97, 97, 97);
  font-size: 14px;
  text-align: center;
}

.pos_rel {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.icon {
  vertical-align: middle;
  width: 100%;
  /* margin-top: 10%; */
  /* transform: translateY(20%); */

  /* height: 60px; */
}
.checkinfo {
  white-space: inherit;
  text-align: center;
  word-wrap: break-word;
}
.line_bot {
  font-weight: 600;
  text-decoration-line: underline;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.home_form_item {
  /* margin-left:200px; */
  position: relative;
  text-align: left;
  /* border-radius: 20px; */
  background-color: white;
  /* margin-bottom: 20px;
  padding-left: 20px; */
}
/* .home_form_item1 {
  position: relative;
  text-align: left;
  border-radius: 20px;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
} */
.home_form_item1 .el-form-item {
  margin-bottom: 0;
}
.bor_t {
  border-top: 1px solid #ddd;
}
.bor_green {
  width: 50px;
  height: 4px;
  position: absolute;
  left: 8%;
  z-index: 1000;
  background-color: #4cd964;
  border-radius: 3px;
}
.home_form {
  /* width: 95%; */
  /* margin: 10px 10px; */
  border-radius: 10px;
  border: 1px solid #e8e9eb;
}
#all {
  width: 1200px;
}
.font_12 {
  font-size: 12px;
}
  .home_btn {
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 5px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 150px;
    }
.home1 {
  /* position: relative; */
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  /* padding-top: 10px; */
  background-color: #f8fafc;
  /* border: 1px solid #eee; */
  border-radius: 10px;
  /* box-shadow: 0px 0px 5px rgb(206, 205, 205); */
}
.home_input {
  border-top: 1px solid #eeeeee;
  border-radius: 10px;
  border: 0;
}
/* .home_form_item input,
.home_form_item1 input {
  background-color: rgb(245, 255, 255);
   border-left: 0;
  border-right: 0;
  border-bottom: 0;
} */
.font_16 {
  font-size: 16px;
}
.font_bold {
  font-weight: 600;
}
/* input[type="radio" i] {
  width: 25px;
  height: 25px;
} */
input[type="checkbox" i] {
  width: 25px;
  height: 25px;
}
.form_item {
  margin-left: 20px;
}

.language_rule_text {
  margin-left: 10px;
}

.el-form-item__error {
  height: 60px;
  word-break: break-word;
}
.el-radio__inner {
  border: 1px solid #4cd964;
}
.el-radio {
  margin-bottom: 10px;
}
.el-radio-group {
  text-align: left;
}
.el-form-item {
  /* border:1px solid #ddd; */
  /* padding: 0 20px 30px 20px; */
  padding: 0 10px 0px 10px;
  margin: 0;
}
.el-input__inner {
  padding: 0px 0px 0px 5px;
}
.el-form-item__content {
  line-height: 20px;
}
.el-select {
  width: 100%;
}
.el-input__inner::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #888;
  font-size: 12px;
}

.el-input__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #888;
  font-size: 12px;
}

.el-input__inner:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #888;
  font-size: 12px;
}
.el-button--primary {
  width: 100%;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #409eff;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #40ffd6;
}
.el-checkbox__inner,
.el-checkbox__inner.is-focus {
  border-color: #409eff;
}
/* 自定义提示框样式 */
.el-message {
  min-width: 50px !important;
  /* width: 75%; */
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  /* border-color: #0616f7; */
  position: fixed;
  /* left: 50%; */
  /* top: 50% !important; */
  /* transform: translate(-50%, -50%); */
  /* background-color: #edf2fc; */
  transition: opacity 0.3s, transform 0.4s, top 0.4s;
  padding: 15px 15px 15px 20px;
  display: flex;
  align-items: center;
}
.el-message--warning {
  border-color: #f8d7a9;
}
.el-message--error {
  border-color: #f73838;
}
.el-message--success {
  border-color: #4cd964;
}
.el-message-box {
  width: 50%;
}
.el-message-box__btns {
  display: flex;
  justify-content: space-around;
}
.el-checkbox {
  /* display: flex; */
  width: 100%;
  white-space: inherit;
  text-align: unset;

  word-break: break-word;
}
.el-checkbox__label {
  display: unset;
  width: 80px;
  font-size: 12px;
  margin-left: -24px;
}
.el-checkbox__input {
  /* display: none; */
  transform: translateY(-85px);
}
.el-select-dropdown__wrap .el-scrollbar__wrap {
  margin: 0;
}
 .submit {
      background-color: #409eff;
      color: #fff;
    }
</style>
