<template>
  <!-- content  -->
  <main class="l-main" v-title :data-title="$route.name">
    <div class="main row">
      <article>
        <div class="body field clearfix">
          <div class="vc_row wpb_row vc_row-fluid">
            <div
              class="wpb_column vc_column_container vc_col-sm-12"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="10"
                  data-heighttablet="20"
                  data-heightdesktop="50"
                ></div>

                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-8"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="wpb_text_column wpb_content_element about-us-text-block"
                      >
                        <div class="wpb_wrapper">
                          <p>
                            <strong
                              >Smart User & Silicon Lighting (VEU Accredited
                              Person) participates in the Victorian Energy
                              Upgrades (VEU) program to deliver energy
                              efficiency improvements which are more affordable
                              for consumers, and reduces their long term energy
                              consumption. Our accreditation can be found at the
                              <a
                                href="https://www.veu-registry.vic.gov.au/public/APRegister.aspx?id=401"
                                >Victorian Energy Upgrades</a
                              >. We have helped over 200,000 homes and
                              businesses save energy by using more energy
                              efficient products and changed over 2 Million
                              inefficient globes to LEDs under VEU
                              program.</strong
                            >
                          </p>
                          <p>
                            <strong
                              >Smart User is now embarking on a new journey by
                              providing consumers with the highly energy
                              efficient heat pump hot water systems, free energy
                              efficiency shower head, free smart in-home display
                              and Solar PV to help Victorians reduce their
                              energy bills and greenhouse gas emissions.</strong
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vc_row wpb_row vc_row-fluid">
            <div
              class="wpb_column vc_column_container vc_col-sm-12"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="20"
                  data-heighttablet="40"
                  data-heightdesktop="70"
                ></div>
              </div>
            </div>
          </div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            class="vc_row wpb_row vc_row-fluid vc_custom_1514911239982 vc_row-has-fill"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-4"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div class="wd-heading text-center">
                  <h2 style="margin: 0; font-size: 40px; color: #ffffff">
                    2,000,000+
                  </h2>

                  <hr
                    style="
                      border-bottom-width: 1px;
                      border-color: #49d0e5;
                      margin: -10px 0;
                    "
                  />
                  <p style="font-size: 16px; color: #ffffff; line-height: 30px">
                    Inefficient Globes to LEDs
                  </p>
                </div>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-4"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div class="wd-heading text-center">
                  <h2 style="margin: 0; font-size: 40px; color: #ffffff">
                    200,000+
                  </h2>

                  <hr
                    style="
                      border-bottom-width: 1px;
                      border-color: #49d0e5;
                      margin: -10px 0;
                    "
                  />
                  <p style="font-size: 16px; color: #ffffff; line-height: 30px">
                    Happy Clients
                  </p>
                </div>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-4"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div class="wd-heading text-center">
                  <h2 style="margin: 0; font-size: 40px; color: #ffffff">
                    100+
                  </h2>

                  <hr
                    style="
                      border-bottom-width: 1px;
                      border-color: #49d0e5;
                      margin: -20px 0;
                    "
                  />
                  <p style="font-size: 16px; color: #ffffff; line-height: 30px">
                    Licensed Electrician & Plumbers
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            class="vc_row wpb_row vc_row-fluid vc_custom_1627269981297 vc_row-has-fill"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-9"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <h2
                  style="font-size: 45px; color: #ffffff; text-align: left"
                  class="vc_custom_heading"
                >
                  <span>Start Saving Today!</span>
                </h2>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-3"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div class="vc_btn3-container order-btn vc_btn3-left">
                  <a
                    style="background-color: #ffffff; color: #000000"
                    class="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-round vc_btn3-style-custom"
                    href="https://smartuser.com.au/customerdetails"
                    title="Contact"
                    target="_blank"
                    >Book Now For Free Consultation</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
        </div>
      </article>
    </div>
  </main>
  <!-- /content  -->
</template>

<script>
export default {};
</script>

<style>
.vc_custom_1627269981297 {
  margin-bottom: -40px !important;
  padding-top: 50px !important;
  padding-bottom: 40px !important;
  background-color: #84b903 !important;
}
.vc_custom_1514911239982 {
  margin-right: 30px !important;
  margin-left: 30px !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  background-color: #49d0e5 !important;
}
</style>
