<template>
  <div v-title :data-title="$route.name">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div ref="myElement">
      <div v-if="offsetwidth > 850">
        <section class="titlebar">
          <div class="row">
            <h1 id="page-title" class="title text-center">
              Solar Panel System
            </h1>
          </div>
        </section>
        <main class="l-main">
          <div class="row">
            <article>
              <div class="body field clearfix">
                <div class="vc_row wpb_row vc_row-fluid vc_column-gap-25">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-certificate"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">25 Years</h3>

                          <hr />

                          <p class="box-body">
                            Performance<br />
                            Guarantee
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-certificate"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">15 Years</h3>

                          <hr />

                          <p class="box-body">
                            Product<br />
                            Warranty
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-certificate"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">10 Years</h3>

                          <hr />

                          <p class="box-body">
                            Inverter<br />
                            Warranty
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
                <div
                  class="vc_row wpb_row vc_row-fluid vc_custom_1617929303342"
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-3"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-6"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    class="vc_single_image-img"
                                    src="../assets/sp/311617929768_.pic_hd-400x150.png"
                                    width="400"
                                    height="150"
                                    alt="311617929768_.pic_hd"
                                    title="311617929768_.pic_hd"
                                  />
                                </div>
                              </figure>
                            </div>
                            <div class="wd-heading text-center">
                              <h2
                                style="
                                  margin: 0;
                                  font-size: 35px;
                                  color: #000000;
                                "
                              >
                                Types of Solar Panel by SmartUser
                              </h2>

                              <hr
                                style="border-color: #49d0e5; margin: 20px 0"
                              />
                              <p style="color: #8a8a8a">
                                Our commitment to quality and service is
                                unparalleled in the industry.
                              </p>
                            </div>

                            <div
                              class="revaldak_empty_space"
                              data-heightmobile="15"
                              data-heighttablet="15"
                              data-heightdesktop="15"
                              style="height: 15px"
                            ></div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-3"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row wpb_row vc_row-fluid vc_column-gap-25">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="boxes small layout-4 clearfix to-display-none-items-content"
                      >
                        <div class="box-container clearfix">
                          <!-- <a
                            href="http://smartuser.com.au/growatt-inverter-jinko-panel/"
                          > -->
                            <div class="box-icon">
                              <img
                                width="300"
                                height="350"
                                src="../assets/sp/jinko300x370px-300x350.png"
                                class="attachment-revaldak_650x350"
                                alt=""
                                loading="lazy"
                              />
                            </div>
                          <!-- </a> -->

                          <h3 class="box-title-4">
                            Growatt Inverter + Jinko Panel
                          </h3>

                          <p class="box-body">Some text should be hrre...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row wpb_row vc_row-fluid">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="10"
                        data-heighttablet="20"
                        data-heightdesktop="30"
                        style="height: 30px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  data-vc-stretch-content="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1600839432165 vc_row-has-fill vc_row-o-equal-height vc_row-flex"
                  style="background: rgb(241, 244, 249)"
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1600823781184"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>

                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-8"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div class="wd-heading text-left">
                              <h2
                                style="
                                  margin: 0;
                                  font-size: 35px;
                                  color: #020202;
                                "
                              >
                                Cleaner, Greener Solar System for Everyone
                              </h2>

                              <hr
                                style="border-color: #49d0e5; margin: 20px 0"
                              />
                            </div>

                            <div class="wpb_text_column wpb_content_element">
                              <div class="wpb_wrapper">
                                <p class="header2">
                                  <strong
                                    ><span style="color: #ff0000"
                                      ><img
                                        draggable="false"
                                        role="img"
                                        class="emoji"
                                        alt="✔"
                                        src="https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg"
                                      />
                                      $0 Upfront Payment</span
                                    ></strong
                                  >
                                </p>
                                <p class="header2">
                                  <img
                                    draggable="false"
                                    role="img"
                                    class="emoji"
                                    alt="✔"
                                    src="https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg"
                                  />
                                  Limited Government Solar Rebate
                                </p>
                                <p class="header2">
                                  <img
                                    draggable="false"
                                    role="img"
                                    class="emoji"
                                    alt="✔"
                                    src="https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg"
                                  />
                                  Limited Time for Factory Direct Price
                                </p>
                                <p class="header2">
                                  <img
                                    draggable="false"
                                    role="img"
                                    class="emoji"
                                    alt="✔"
                                    src="https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg"
                                  />
                                  Recover Your System Cost in 3-4 Years
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1646986307646"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid"
                  style="
                    position: relative;
                    left: -37.691px;
                    box-sizing: border-box;
                    width: 1361px;
                    padding-left: 37.691px;
                    padding-right: 37.309px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          Solar System Service Process
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div
                        class="vc_row wpb_row vc_inner vc_row-fluid single-img-logistics"
                      >
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-1"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon1.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>The Cost Benefit Ratio</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-2"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon2.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>We Tailor A Solar System For Your Home</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-3"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>Solution &amp; Design</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-4"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>Final Design</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-5"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>Installation</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-6"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>Maintenance &amp; Support</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1600840388900 vc_row-has-fill vc_row-o-equal-height vc_row-flex"
                  style="
                    position: relative;
                    left: -37.691px;
                    box-sizing: border-box;
                    width: 1361px;
                    padding-left: 37.691px;
                    padding-right: 37.309px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1600840346720"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>

                      <div
                        class="wpb_single_image wpb_content_element vc_align_left"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <div
                            class="vc_single_image-wrapper vc_box_border_grey"
                          >
                            <img
                              width="6086"
                              height="3871"
                              src="../assets/sp/image.png"
                              class="vc_single_image-img attachment-full"
                              alt=""
                              loading="lazy"
                              sizes="(max-width: 6086px) 100vw, 6086px"
                            />
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1600840279954"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>

                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-8"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                      </div>
                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-12"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div class="wd-heading text-left">
                              <h2
                                style="
                                  margin: 0;
                                  font-size: 35px;
                                  color: #020202;
                                "
                              >
                                Household Solar Panel System FAQ
                              </h2>

                              <hr
                                style="border-color: #49d0e5; margin: 20px 0"
                              />
                            </div>

                            <div
                              class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_lg"
                            >
                              <div class="vc_toggle_title">
                                <h1
                                  style="font-size: 22px; text-align: left"
                                  class="vc_custom_heading"
                                >
                                  How Does Household Solar Panel System Work ?
                                </h1>

                                <i
                                  class="vc_toggle_icon"
                                  @click="HowDoes = !HowDoes"
                                ></i>
                              </div>
                              <!-- <div class="vc_toggle_content">
                                <p>
                                  Solar panels are made out of photovoltaic
                                  cells that convert the sun’s energy into
                                  electricity.<br />
                                  Photovoltaic cells are sandwiched between
                                  layers of semi-conducting materials such as
                                  silicone. Each layer has different electronic
                                  properties that energise when hit by photons
                                  from sunlight, creating an electric field.
                                  This is known as the photoelectric effect –
                                  and it’s this that creates the current needed
                                  to produce electricity.
                                </p>
                                <p>
                                  Solar panels generate a direct current of
                                  electricity. This is then passed through an
                                  inverter to convert it into an alternating
                                  current, which can then be used by the home or
                                  business the solar panels are attached to. Our
                                  home appliances such as the fridge and
                                  dishwasher use this power instead of drawing
                                  power from the electricity grid.
                                </p>
                              </div> -->
                              <div v-if="HowDoes == true">
                                <p>
                                  Solar panels are made out of photovoltaic
                                  cells that convert the sun’s energy into
                                  electricity.<br />
                                  Photovoltaic cells are sandwiched between
                                  layers of semi-conducting materials such as
                                  silicone. Each layer has different electronic
                                  properties that energise when hit by photons
                                  from sunlight, creating an electric field.
                                  This is known as the photoelectric effect –
                                  and it’s this that creates the current needed
                                  to produce electricity.
                                </p>
                                <p>
                                  Solar panels generate a direct current of
                                  electricity. This is then passed through an
                                  inverter to convert it into an alternating
                                  current, which can then be used by the home or
                                  business the solar panels are attached to. Our
                                  home appliances such as the fridge and
                                  dishwasher use this power instead of drawing
                                  power from the electricity grid.
                                </p>
                              </div>
                            </div>
                            <div
                              class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_lg"
                            >
                              <div class="vc_toggle_title">
                                <h1
                                  style="font-size: 22px; text-align: left"
                                  class="vc_custom_heading"
                                >
                                  How Long Does It Take To Install?
                                </h1>
                                <i
                                  class="vc_toggle_icon"
                                  @click="HowLong = !HowLong"
                                ></i>
                              </div>
                              <!-- <div class="vc_toggle_content">
                                <p>
                                  You can be up and running in no time:
                                  residential solar panel installation will
                                  generally take no more than two days when
                                  installed by a Clean Energy Council accredited
                                  installer.
                                </p>
                                <p>
                                  Solar panels are normally covered for 20-25
                                  years once they are installed and even once
                                  their warranty has run out, they are expected
                                  to last for another 25 years because they need
                                  such little maintenance and upkeep.
                                </p>
                              </div> -->
                              <div v-if="HowLong == true">
                                <p>
                                  You can be up and running in no time:
                                  residential solar panel installation will
                                  generally take no more than two days when
                                  installed by a Clean Energy Council accredited
                                  installer.
                                </p>
                                <p>
                                  Solar panels are normally covered for 20-25
                                  years once they are installed and even once
                                  their warranty has run out, they are expected
                                  to last for another 25 years because they need
                                  such little maintenance and upkeep.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid"
                  style="
                    position: relative;
                    left: -37.691px;
                    box-sizing: border-box;
                    width: 1361px;
                    padding-left: 37.691px;
                    padding-right: 37.309px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          Why Solar Panels
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-1"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-10"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div class="wd-image-text text-left style-3">
                              <div class=" ">
                                <a class="picture-div left" href=""> </a>

                                <div class="text-div">
                                  <h6 class="wd-title-element">
                                    What are solar panels?
                                  </h6>

                                  <p>
                                    Solar panels are made of photovoltaic (PV)
                                    cells, which turn sunlight into electricity.
                                    This electricity can then be fed into your
                                    home's mains electricity supply.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-1"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                      </div>
                      <div
                        class="wpb_single_image wpb_content_element vc_align_center"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <div
                            class="vc_single_image-wrapper vc_box_border_grey"
                          >
                            <img
                              width="1199"
                              height="483"
                              src="../assets/sp/image.jpeg"
                              class="vc_single_image-img attachment-full"
                              alt=""
                              loading="lazy"
                              sizes="(max-width: 1199px) 100vw, 1199px"
                            />
                          </div>
                        </figure>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1601263005108 vc_row-has-fill"
                  style="
                    position: relative;
                    left: -37.691px;
                    box-sizing: border-box;
                    width: 1361px;
                    padding-left: 37.691px;
                    padding-right: 37.309px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 100px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          Advantage of Solar Panels
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1601263010873 vc_row-has-fill vc_column-gap-25"
                  style="
                    position: relative;
                    left: -10.691px;
                    box-sizing: border-box;
                    width: 1361px;
                    padding-left: 10.691px;
                    padding-right: 10.309px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600841664130"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 852px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front cheaper"
                              :style="backgroundStyle"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Cheaper electricity bills
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Cheaper electricity bills
                                </h2>
                                <p>
                                  With an increase in electric gadgets comes an
                                  increase in utility bills. You can reduce your
                                  electricity bills by installing solar panels
                                  on your house.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600841673239"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 852px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 253px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              style="background-image: url()"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Earn money back
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Earn money back
                                </h2>
                                <p>
                                  Once you have your residential solar panel
                                  system installed, you can make money by
                                  selling surplus electricity back to the grid.
                                  This is called a feed-in tariff.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600841947439"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 852px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              style="background-image: url()"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Reduce your carbon footprint
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Reduce your carbon footprint
                                </h2>
                                <p>
                                  Switching to solar energy setup significantly
                                  reduces your carbon footprint as it is a
                                  green, renewable source of energy.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600842054460"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 852px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 253px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              :style="backgroundyear"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Year-round efficiency
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Year-round efficiency
                                </h2>
                                <p>
                                  Solar panels work efficiently all year round.
                                  While they work optimally in summer, they also
                                  produce a considerable amount of electricity
                                  during cloudy days &amp; winter.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600842148320"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 852px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              style="background-image: url()"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Low maintenance
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Low maintenance
                                </h2>
                                <p>
                                  Solar panels have an average lifespan of 25-30
                                  years, and require only little maintenance
                                  over these years.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600842154846"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 852px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              style="background-image: url()"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Independent from the grid
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Independent from the grid
                                </h2>
                                <p>
                                  Solar PV panel installation is an affordable,
                                  effective solution for remote homes in rural
                                  areas of the country.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1601263102740 vc_row-has-fill"
                  style="
                    position: relative;
                    left: -37.691px;
                    box-sizing: border-box;
                    width: 1361px;
                    padding-left: 37.691px;
                    padding-right: 37.309px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="120"
                        style="height: 120px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="false"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1646985860655 vc_row-has-fill"
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="90"
                        style="height: 90px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #ffffff">
                          Solar Panel: Renewable Power Incentives in Victoria
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="40"
                        style="height: 40px"
                      ></div>

                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-6"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div class="boxes small layout-4 clearfix">
                              <div class="box-container clearfix">
                                <!-- <a
                                  href="https://www.veu-registry.vic.gov.au/public/APRegister.aspx?id=401"
                                  onclick="javascript:window.open('https://www.veu-registry.vic.gov.au/public/APRegister.aspx?id=401'); return false;"
                                > -->
                                  <div class="box-icon"></div>
                                <!-- </a> -->

                                <h3 class="box-title-4">Block title</h3>

                                <p class="box-body">
                                  Some text should be hrre...
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-6"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="vc_toggle vc_toggle_default vc_toggle_color_white vc_toggle_size_lg"
                            >
                              <div class="vc_toggle_title">
                                <p
                                  style="
                                    font-size: 22px;
                                    color: #ffffff;
                                    text-align: left;
                                  "
                                  class="vc_custom_heading"
                                >
                                  What is it all about and how much can I save?
                                </p>
                                <i
                                  class="vc_toggle_icon"
                                  @click="showWhat = !showWhat"
                                ></i>
                              </div>
                              <!-- <div class="vc_toggle_content">
                                <p>
                                  <span style="color: #f0f0f0">
                                    The Victorian Solar Homes scheme was
                                    launched in 2018 and the Victorian
                                    government solar rebate scheme aims to make
                                    it easier for Victorians to access and
                                    install solar power systems at home.</span
                                  >
                                </p>
                                <p>
                                  <span style="color: #f0f0f0"
                                    >Solar Victoria is providing a rebate of up
                                    to $1,400 for solar panel (PV) system
                                    installation, for homeowners and rental
                                    properties plus the option of an
                                    interest-free loan. To further reduce
                                    installation costs, householders can apply
                                    for an interest-free loan, for an amount
                                    equivalent to their rebate amount. The loan
                                    is required to be repaid over four years or
                                    can be repaid sooner in one lump sum. Smart
                                    user is also offering Interest-free loans
                                    for the remaining system costs with no
                                    up-front cost involved and delivering
                                    savings of $1,073 on average annual
                                    household electricity bills from solar PV
                                    panels (compared to the $890 estimated at
                                    program launch) .</span
                                  >
                                </p>
                              </div> -->
                              <div v-if="showWhat == true">
                                <p>
                                  <span style="color: #f0f0f0">
                                    The Victorian Solar Homes scheme was
                                    launched in 2018 and the Victorian
                                    government solar rebate scheme aims to make
                                    it easier for Victorians to access and
                                    install solar power systems at home.</span
                                  >
                                </p>
                                <p>
                                  <span style="color: #f0f0f0"
                                    >Solar Victoria is providing a rebate of up
                                    to $1,400 for solar panel (PV) system
                                    installation, for homeowners and rental
                                    properties plus the option of an
                                    interest-free loan. To further reduce
                                    installation costs, householders can apply
                                    for an interest-free loan, for an amount
                                    equivalent to their rebate amount. The loan
                                    is required to be repaid over four years or
                                    can be repaid sooner in one lump sum. Smart
                                    user is also offering Interest-free loans
                                    for the remaining system costs with no
                                    up-front cost involved and delivering
                                    savings of $1,073 on average annual
                                    household electricity bills from solar PV
                                    panels (compared to the $890 estimated at
                                    program launch) .</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div
                              class="vc_toggle vc_toggle_default vc_toggle_color_white vc_toggle_size_lg"
                            >
                              <div class="vc_toggle_title">
                                <p
                                  style="
                                    font-size: 22px;
                                    color: #ffffff;
                                    text-align: left;
                                  "
                                  class="vc_custom_heading"
                                >
                                  Household Eligibility Criteria
                                </p>
                                <i
                                  class="vc_toggle_icon"
                                  @click="Household = !Household"
                                ></i>
                              </div>
                              <div v-if="Household == true">
                                <p>
                                  <span style="color: #f0f0f0"
                                    >The owner-occupier of the property where
                                    the system is being installed.<br />
                                    They have not already received a rebate as
                                    an owner-occupier under the Solar Homes
                                    Program.<br />
                                    The owners have a combined household taxable
                                    income of less than $180,000 per year (based
                                    on last year’s Australian Tax Office Notice
                                    of Assessment).<br />
                                    It is an existing property, valued at under
                                    $3 million.<br />
                                    The property address has not previously
                                    taken part in the Solar Homes Program » they
                                    do not have an existing solar PV
                                    system.</span
                                  >
                                </p>
                              </div>
                              <!-- <div class="vc_toggle_content">
                                <p>
                                  <span style="color: #f0f0f0"
                                    >The owner-occupier of the property where
                                    the system is being installed.<br />
                                    They have not already received a rebate as
                                    an owner-occupier under the Solar Homes
                                    Program.<br />
                                    The owners have a combined household taxable
                                    income of less than $180,000 per year (based
                                    on last year’s Australian Tax Office Notice
                                    of Assessment).<br />
                                    It is an existing property, valued at under
                                    $3 million.<br />
                                    The property address has not previously
                                    taken part in the Solar Homes Program » they
                                    do not have an existing solar PV
                                    system.</span
                                  >
                                </p>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="40"
                        style="height: 40px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <!-- <div
                  id="demo"
                  data-vc-full-width="true"
                  data-vc-full-width-init="false"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1627274335156 vc_row-has-fill"
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-9"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <h2
                        style="
                          font-size: 45px;
                          color: #ffffff;
                          text-align: left;
                        "
                        class="vc_custom_heading"
                      >
                        <a
                          href="https://www.smart-user.co/new_app/24"
                          onclick="javascript:window.open('https://www.smart-user.co/new_app/24'); return false;"
                          >Start Saving Today!</a
                        >
                      </h2>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="vc_btn3-container order-btn vc_btn3-left">
                        <a
                          style="background-color: #ffffff; color: #000000"
                          class="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-round vc_btn3-style-custom"
                          href="https://smartuser.com.au/customerdetails/"
                          title="Contact"
                          target="_blank"
                          >Book Now For Free Consultation</a
                        >
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="vc_row-full-width vc_clearfix"></div>
              </div>
            </article>
          </div>
          <div style="position: relative">
            <img style="width: 100%" src="../assets/sp/start.jpg" alt="" />

            <img
              style="
                width: 18%;
                position: absolute;
                top: 50%;
                left: 70%;
                cursor: pointer;
                transform: translate(-50%, -50%);
              "
              @click="goto()"
              src="../assets/sp/startbutton.png"
              alt=""
            />
          </div>
        </main>
      </div>
      <div v-else>
        <section class="titlebar">
          <div class="row">
            <h1 id="page-title" class="title text-center">
              Solar Panel System
            </h1>
          </div>
        </section>
        <main class="l-main">
          <div class="main row">
            <article>
              <div class="body field clearfix">
                <div class="vc_row wpb_row vc_row-fluid vc_column-gap-25">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-certificate"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">25 Years</h3>

                          <hr />

                          <p class="box-body">
                            Performance<br />
                            Guarantee
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-certificate"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">15 Years</h3>

                          <hr />

                          <p class="box-body">
                            Product<br />
                            Warranty
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-certificate"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">10 Years</h3>

                          <hr />

                          <p class="box-body">
                            Inverter<br />
                            Warranty
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-1/5"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
                <div
                  class="vc_row wpb_row vc_row-fluid vc_custom_1617929303342"
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-3"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-6"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    class="vc_single_image-img"
                                    src="../assets/sp/311617929768_.pic_hd-400x150.png"
                                    width="400"
                                    height="150"
                                    alt="311617929768_.pic_hd"
                                    title="311617929768_.pic_hd"
                                  />
                                </div>
                              </figure>
                            </div>
                            <div class="wd-heading text-center">
                              <h2
                                style="
                                  margin: 0;
                                  font-size: 35px;
                                  color: #000000;
                                "
                              >
                                Types of Solar Panel by SmartUser
                              </h2>

                              <hr
                                style="border-color: #49d0e5; margin: 20px 0"
                              />
                              <p style="color: #8a8a8a">
                                Our commitment to quality and service is
                                unparalleled in the industry.
                              </p>
                            </div>

                            <div
                              class="revaldak_empty_space"
                              data-heightmobile="15"
                              data-heighttablet="15"
                              data-heightdesktop="15"
                              style="height: 15px"
                            ></div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-3"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row wpb_row vc_row-fluid vc_column-gap-25">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="boxes small layout-4 clearfix to-display-none-items-content"
                      >
                        <div class="box-container clearfix">
                          <!-- <a
                            href="http://smartuser.com.au/growatt-inverter-jinko-panel/"
                          > -->
                            <div class="box-icon">
                              <img
                                width="300"
                                height="350"
                                src="../assets/sp/jinko300x370px-300x350.png"
                                class="attachment-revaldak_650x350"
                                alt=""
                                loading="lazy"
                              />
                            </div>
                          <!-- </a> -->

                          <h3 class="box-title-4">
                            Growatt Inverter + Jinko Panel
                          </h3>

                          <p class="box-body">Some text should be hrre...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row wpb_row vc_row-fluid">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="10"
                        data-heighttablet="20"
                        data-heightdesktop="30"
                        style="height: 10px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  data-vc-stretch-content="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1600839432165 vc_row-has-fill vc_row-o-equal-height vc_row-flex"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1600823781184"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>

                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-8"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div class="wd-heading text-left">
                              <h2
                                style="
                                  margin: 0;
                                  font-size: 35px;
                                  color: #020202;
                                "
                              >
                                Cleaner, Greener Solar System for Everyone
                              </h2>

                              <hr
                                style="border-color: #49d0e5; margin: 20px 0"
                              />
                            </div>

                            <div class="wpb_text_column wpb_content_element">
                              <div class="wpb_wrapper">
                                <p class="header2">
                                  <strong
                                    ><span style="color: #ff0000"
                                      ><img
                                        draggable="false"
                                        role="img"
                                        class="emoji"
                                        alt="✔"
                                        src="https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg"
                                      />
                                      $0 Upfront Payment</span
                                    ></strong
                                  >
                                </p>
                                <p class="header2">
                                  <img
                                    draggable="false"
                                    role="img"
                                    class="emoji"
                                    alt="✔"
                                    src="https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg"
                                  />
                                  Limited Government Solar Rebate
                                </p>
                                <p class="header2">
                                  <img
                                    draggable="false"
                                    role="img"
                                    class="emoji"
                                    alt="✔"
                                    src="https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg"
                                  />
                                  Limited Time for Factory Direct Price
                                </p>
                                <p class="header2">
                                  <img
                                    draggable="false"
                                    role="img"
                                    class="emoji"
                                    alt="✔"
                                    src="https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg"
                                  />
                                  Recover Your System Cost in 3-4 Years
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1646986307646"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          Solar System Service Process
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div
                        class="vc_row wpb_row vc_inner vc_row-fluid single-img-logistics"
                      >
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-1"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon1.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>The Cost Benefit Ratio</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-2"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon2.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>We Tailor A Solar System For Your Home</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-3"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>Solution &amp; Design</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-4"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>Final Design</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-5"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>Installation</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="wpb_single_image wpb_content_element vc_align_center single-img-6"
                            >
                              <figure class="wpb_wrapper vc_figure">
                                <div
                                  class="vc_single_image-wrapper vc_box_border_grey"
                                >
                                  <img
                                    width="1"
                                    height="1"
                                    src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                                    class="vc_single_image-img attachment-thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </figure>
                            </div>

                            <div
                              class="wpb_raw_code wpb_content_element wpb_raw_html"
                            >
                              <div class="wpb_wrapper">
                                <h3>Maintenance &amp; Support</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1600840388900 vc_row-has-fill vc_row-o-equal-height vc_row-flex"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1600840346720"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>

                      <div
                        class="wpb_single_image wpb_content_element vc_align_left"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <div
                            class="vc_single_image-wrapper vc_box_border_grey"
                          >
                            <img
                              width="6086"
                              height="3871"
                              src="../assets/sp/image.png"
                              class="vc_single_image-img attachment-full"
                              alt=""
                              loading="lazy"
                              sizes="(max-width: 6086px) 100vw, 6086px"
                            />
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1600840279954"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>

                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-8"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-2"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                      </div>
                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-12"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div class="wd-heading text-left">
                              <h2
                                style="
                                  margin: 0;
                                  font-size: 35px;
                                  color: #020202;
                                "
                              >
                                Household Solar Panel System FAQ
                              </h2>

                              <hr
                                style="border-color: #49d0e5; margin: 20px 0"
                              />
                            </div>

                            <div
                              class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_lg"
                            >
                              <div class="vc_toggle_title">
                                <h1
                                  style="font-size: 22px; text-align: left"
                                  class="vc_custom_heading"
                                >
                                  How Does Household Solar Panel System Work ?
                                </h1>
                                <i
                                  class="vc_toggle_icon"
                                  @click="HowDoes = !HowDoes"
                                ></i>
                              </div>
                              <div v-if="HowDoes == true">
                                <p>
                                  Solar panels are made out of photovoltaic
                                  cells that convert the sun’s energy into
                                  electricity.<br />
                                  Photovoltaic cells are sandwiched between
                                  layers of semi-conducting materials such as
                                  silicone. Each layer has different electronic
                                  properties that energise when hit by photons
                                  from sunlight, creating an electric field.
                                  This is known as the photoelectric effect –
                                  and it’s this that creates the current needed
                                  to produce electricity.
                                </p>
                                <p>
                                  Solar panels generate a direct current of
                                  electricity. This is then passed through an
                                  inverter to convert it into an alternating
                                  current, which can then be used by the home or
                                  business the solar panels are attached to. Our
                                  home appliances such as the fridge and
                                  dishwasher use this power instead of drawing
                                  power from the electricity grid.
                                </p>
                              </div>
                              <!-- <div class="vc_toggle_content">
                                <p>
                                  Solar panels are made out of photovoltaic
                                  cells that convert the sun’s energy into
                                  electricity.<br />
                                  Photovoltaic cells are sandwiched between
                                  layers of semi-conducting materials such as
                                  silicone. Each layer has different electronic
                                  properties that energise when hit by photons
                                  from sunlight, creating an electric field.
                                  This is known as the photoelectric effect –
                                  and it’s this that creates the current needed
                                  to produce electricity.
                                </p>
                                <p>
                                  Solar panels generate a direct current of
                                  electricity. This is then passed through an
                                  inverter to convert it into an alternating
                                  current, which can then be used by the home or
                                  business the solar panels are attached to. Our
                                  home appliances such as the fridge and
                                  dishwasher use this power instead of drawing
                                  power from the electricity grid.
                                </p>
                              </div> -->
                            </div>
                            <div
                              class="vc_toggle vc_toggle_default vc_toggle_color_default vc_toggle_size_lg"
                            >
                              <div class="vc_toggle_title">
                                <h1
                                  style="font-size: 22px; text-align: left"
                                  class="vc_custom_heading"
                                >
                                  How Long Does It Take To Install?
                                </h1>
                                <i
                                  class="vc_toggle_icon"
                                  @click="HowLong = !HowLong"
                                ></i>
                              </div>
                              <div v-if="HowLong == true">
                                <p>
                                  You can be up and running in no time:
                                  residential solar panel installation will
                                  generally take no more than two days when
                                  installed by a Clean Energy Council accredited
                                  installer.
                                </p>
                                <p>
                                  Solar panels are normally covered for 20-25
                                  years once they are installed and even once
                                  their warranty has run out, they are expected
                                  to last for another 25 years because they need
                                  such little maintenance and upkeep.
                                </p>
                              </div>
                              <!-- <div class="vc_toggle_content">
                                <p>
                                  You can be up and running in no time:
                                  residential solar panel installation will
                                  generally take no more than two days when
                                  installed by a Clean Energy Council accredited
                                  installer.
                                </p>
                                <p>
                                  Solar panels are normally covered for 20-25
                                  years once they are installed and even once
                                  their warranty has run out, they are expected
                                  to last for another 25 years because they need
                                  such little maintenance and upkeep.
                                </p>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          Why Solar Panels
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-1"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-10"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div class="wd-image-text text-left style-3">
                              <div class=" ">
                                <a class="picture-div left" href=""> </a>

                                <div class="text-div">
                                  <h6 class="wd-title-element">
                                    What are solar panels?
                                  </h6>

                                  <p>
                                    Solar panels are made of photovoltaic (PV)
                                    cells, which turn sunlight into electricity.
                                    This electricity can then be fed into your
                                    home's mains electricity supply.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-1"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper"></div>
                        </div>
                      </div>
                      <div
                        class="wpb_single_image wpb_content_element vc_align_center"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <div
                            class="vc_single_image-wrapper vc_box_border_grey"
                          >
                            <img
                              width="1199"
                              height="483"
                              src="../assets/sp/image.jpeg"
                              class="vc_single_image-img attachment-full"
                              alt=""
                              loading="lazy"
                              sizes="(max-width: 1199px) 100vw, 1199px"
                            />
                          </div>
                        </figure>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1601263005108 vc_row-has-fill"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="100"
                        style="height: 20px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          Advantage of Solar Panels
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1601263010873 vc_row-has-fill vc_column-gap-25"
                  style="
                    position: relative;
                    left: 27px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600841664130"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 1560px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              :style="backgroundStyle"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Cheaper electricity bills
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Cheaper electricity bills
                                </h2>
                                <p>
                                  With an increase in electric gadgets comes an
                                  increase in utility bills. You can reduce your
                                  electricity bills by installing solar panels
                                  on your house.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600841673239"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 1560px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              style="background-image: url()"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Earn money back
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Earn money back
                                </h2>
                                <p>
                                  Once you have your residential solar panel
                                  system installed, you can make money by
                                  selling surplus electricity back to the grid.
                                  This is called a feed-in tariff.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600841947439"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 1560px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              style="background-image: url()"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Reduce your carbon footprint
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Reduce your carbon footprint
                                </h2>
                                <p>
                                  Switching to solar energy setup significantly
                                  reduces your carbon footprint as it is a
                                  green, renewable source of energy.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600842054460"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 1560px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              :style="backgroundyear"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Year-round efficiency
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Year-round efficiency
                                </h2>
                                <p>
                                  Solar panels work efficiently all year round.
                                  While they work optimally in summer, they also
                                  produce a considerable amount of electricity
                                  during cloudy days &amp; winter.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600842148320"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 1560px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              style="background-image: url()"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Low maintenance
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Low maintenance
                                </h2>
                                <p>
                                  Solar panels have an average lifespan of 25-30
                                  years, and require only little maintenance
                                  over these years.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600842154846"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="vc-hoverbox-wrapper vc-hoverbox-shape--rounded vc-hoverbox-align--center vc-hoverbox-direction--reverse vc-hoverbox-width--100"
                        ontouchstart=""
                      >
                        <div class="vc-hoverbox" style="perspective: 1560px">
                          <div
                            class="vc-hoverbox-inner"
                            style="min-height: 250px"
                          >
                            <div
                              class="vc-hoverbox-block vc-hoverbox-front"
                              style="background-image: url()"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-front-inner"
                              >
                                <h2 style="text-align: center">
                                  Independent from the grid
                                </h2>
                              </div>
                            </div>
                            <div
                              class="vc-hoverbox-block vc-hoverbox-back"
                              style="background-color: #ebebeb"
                            >
                              <div
                                class="vc-hoverbox-block-inner vc-hoverbox-back-inner"
                              >
                                <h2 style="text-align: center">
                                  Independent from the grid
                                </h2>
                                <p>
                                  Solar PV panel installation is an affordable,
                                  effective solution for remote homes in rural
                                  areas of the country.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1601263102740 vc_row-has-fill"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="120"
                        style="height: 20px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1646985860655 vc_row-has-fill"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-12"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="90"
                        style="height: 20px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #ffffff">
                          Solar Panel: Renewable Power Incentives in Victoria
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="40"
                        style="height: 20px"
                      ></div>

                      <div class="vc_row wpb_row vc_inner vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-6"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div class="boxes small layout-4 clearfix">
                              <div class="box-container clearfix">
                                <!-- <a
                                  href="https://www.veu-registry.vic.gov.au/public/APRegister.aspx?id=401"
                                  onclick="javascript:window.open('https://www.veu-registry.vic.gov.au/public/APRegister.aspx?id=401'); return false;"
                                > -->
                                  <div class="box-icon"></div>
                                <!-- </a> -->

                                <h3 class="box-title-4">Block title</h3>

                                <p class="box-body">
                                  Some text should be hrre...
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="wpb_column vc_column_container vc_col-sm-6"
                          data-equalizer-watch=""
                        >
                          <div class="wpb_wrapper">
                            <div
                              class="vc_toggle vc_toggle_default vc_toggle_color_white vc_toggle_size_lg"
                            >
                              <div class="vc_toggle_title">
                                <p
                                  style="
                                    font-size: 22px;
                                    color: #ffffff;
                                    text-align: left;
                                  "
                                  class="vc_custom_heading"
                                >
                                  What is it all about and how much can I save?
                                </p>
                                <i
                                  class="vc_toggle_icon"
                                  @click="showWhat = !showWhat"
                                ></i>
                              </div>
                              <div v-if="showWhat == true">
                                <p>
                                  <span style="color: #f0f0f0">
                                    The Victorian Solar Homes scheme was
                                    launched in 2018 and the Victorian
                                    government solar rebate scheme aims to make
                                    it easier for Victorians to access and
                                    install solar power systems at home.</span
                                  >
                                </p>
                                <p>
                                  <span style="color: #f0f0f0"
                                    >Solar Victoria is providing a rebate of up
                                    to $1,400 for solar panel (PV) system
                                    installation, for homeowners and rental
                                    properties plus the option of an
                                    interest-free loan. To further reduce
                                    installation costs, householders can apply
                                    for an interest-free loan, for an amount
                                    equivalent to their rebate amount. The loan
                                    is required to be repaid over four years or
                                    can be repaid sooner in one lump sum. Smart
                                    user is also offering Interest-free loans
                                    for the remaining system costs with no
                                    up-front cost involved and delivering
                                    savings of $1,073 on average annual
                                    household electricity bills from solar PV
                                    panels (compared to the $890 estimated at
                                    program launch) .</span
                                  >
                                </p>
                              </div>
                              <!-- <div class="vc_toggle_content">
                                <p>
                                  <span style="color: #f0f0f0">
                                    The Victorian Solar Homes scheme was
                                    launched in 2018 and the Victorian
                                    government solar rebate scheme aims to make
                                    it easier for Victorians to access and
                                    install solar power systems at home.</span
                                  >
                                </p>
                                <p>
                                  <span style="color: #f0f0f0"
                                    >Solar Victoria is providing a rebate of up
                                    to $1,400 for solar panel (PV) system
                                    installation, for homeowners and rental
                                    properties plus the option of an
                                    interest-free loan. To further reduce
                                    installation costs, householders can apply
                                    for an interest-free loan, for an amount
                                    equivalent to their rebate amount. The loan
                                    is required to be repaid over four years or
                                    can be repaid sooner in one lump sum. Smart
                                    user is also offering Interest-free loans
                                    for the remaining system costs with no
                                    up-front cost involved and delivering
                                    savings of $1,073 on average annual
                                    household electricity bills from solar PV
                                    panels (compared to the $890 estimated at
                                    program launch) .</span
                                  >
                                </p>
                              </div> -->
                            </div>
                            <div
                              class="vc_toggle vc_toggle_default vc_toggle_color_white vc_toggle_size_lg"
                            >
                              <div class="vc_toggle_title">
                                <p
                                  style="
                                    font-size: 22px;
                                    color: #ffffff;
                                    text-align: left;
                                  "
                                  class="vc_custom_heading"
                                >
                                  Household Eligibility Criteria
                                </p>
                                <i
                                  class="vc_toggle_icon"
                                  @click="Household = !Household"
                                ></i>
                              </div>
                              <div v-if="Household == true">
                                <p>
                                  <span style="color: #f0f0f0"
                                    >The owner-occupier of the property where
                                    the system is being installed.<br />
                                    They have not already received a rebate as
                                    an owner-occupier under the Solar Homes
                                    Program.<br />
                                    The owners have a combined household taxable
                                    income of less than $180,000 per year (based
                                    on last year’s Australian Tax Office Notice
                                    of Assessment).<br />
                                    It is an existing property, valued at under
                                    $3 million.<br />
                                    The property address has not previously
                                    taken part in the Solar Homes Program » they
                                    do not have an existing solar PV
                                    system.</span
                                  >
                                </p>
                              </div>
                              <!-- <div class="vc_toggle_content">
                                <p>
                                  <span style="color: #f0f0f0"
                                    >The owner-occupier of the property where
                                    the system is being installed.<br />
                                    They have not already received a rebate as
                                    an owner-occupier under the Solar Homes
                                    Program.<br />
                                    The owners have a combined household taxable
                                    income of less than $180,000 per year (based
                                    on last year’s Australian Tax Office Notice
                                    of Assessment).<br />
                                    It is an existing property, valued at under
                                    $3 million.<br />
                                    The property address has not previously
                                    taken part in the Solar Homes Program » they
                                    do not have an existing solar PV
                                    system.</span
                                  >
                                </p>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="40"
                        data-heightdesktop="40"
                        style="height: 20px"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1627274335156 vc_row-has-fill"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-9"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <h2
                        style="
                          font-size: 45px;
                          color: #ffffff;
                          text-align: left;
                        "
                        class="vc_custom_heading"
                      >
                        <!-- href="https://www.smart-user.co/new_app/24"
                          onclick="javascript:window.open('https://www.smart-user.co/new_app/24'); return false;" -->
                        <a
                        
                          >Start Saving Today!</a
                        >
                      </h2>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="vc_btn3-container order-btn vc_btn3-left">
                        <a
                          style="background-color: #ffffff; color: #000000"
                          class="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-round vc_btn3-style-custom"
                          href="/Customerdetails"
                          title="Contact"
                          target="_blank"
                          >Book Now For Free Consultation</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
              </div>
            </article>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      HowLong: false,
      HowDoes: false,

      Household: false,
      showWhat: false,
      offsetwidth: document.documentElement.clientWidth,
    };
  },
  computed: {
    backgroundyear() {
      return {
        backgroundImage: `url(${require("../assets/sp/AdobeStock_153090081-1024x683.jpeg")})`,
        // 其他样式...
      };
    },

    backgroundStyle() {
      return {
        backgroundImage: `url(${require("../assets/sp/6551601268571_.pic_hd.png")})`,
        // 其他样式...
      };
    },
  },
  mounted() {},

  methods: {
    goto() {
      window.open("/", "_blank");
    },
    // goinfo() {
    //   // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
    //   this.$emit("fun", "/CustomerDetails");
    // },
  },
};
</script>

<style scoped>
/* ::v-deep.fa-certificate:before {
    content: "\f0a3" ;
}
::v-deep.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}
::v-deep.primary-color_color, .layout-4 .box-container h3.box-title-4, .wd-title-element, .wd-image-text h4, section.corporate .menu-item a i, .box-container:hover .box-title, .blog-posts i, div.boxes.small.layout-3 .box-icon i {
    color: rgba(38, 95, 180, 1);
} */
.vc_custom_1627274335156 {
  margin-bottom: -80px !important;
  padding-top: 50px !important;
  padding-bottom: 80px !important;
  background-color: #84b903 !important;
}
img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}
.cheaper {
  /* background: url("../assets/sp/6551601268571_.pic_hd.png");
  width: 100%;
    background-size: 100%;
      background-repeat: no-repeat; */
}
.titlebar {
  width: 100%;
  height: 0;
  padding-bottom: 20.45%;
  background: url("../assets/sp/solar-header.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
/* .vc_custom_1627274335156 {
    margin-bottom: -80px !important;
    padding-top: 50px !important;
    padding-bottom: 80px !important;
    background-color: #84b903 !important;
} */
.vc_custom_1646985860655 {
  background-image: url("../assets/sp/solar-header-1.jpg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.special-link {
  text-decoration: underline;
}

.mt_20 {
  margin-top: 20px;
}
.mt_10 {
  margin-top: 10px;
}
.text_bold {
  font-weight: 700;
}
.pcss {
  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
}
.titlecss {
  font-family: Poppins;
  font-style: normal;
}
</style>
