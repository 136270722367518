<template>
  <div>
    <section class="l-footer-columns">
      <h3 class="hide">Footer</h3>
      <div class="footer-img-wrapper">
        <img src="../../public/static/Img/logo.png" alt="footer-logo" />
      </div>
      <div class="row">
        <section class="block">
          <div class="large-3 columns">
            <div class="widget_text">
              <h2 class="block-title">About Us</h2>
              <div class="textwidget">
                <p>
                  Smart User participates in the Victorian Energy Upgrades (VEU)
                  program to deliver energy efficiency improvements which are
                  more affordable for consumers, and reduces their long term
                  energy consumption. Our accreditation can be found at the
                  <a
                    href="https://www.veu-registry.vic.gov.au/public/APRegister.aspx?id=401"
                    style="color: #265fb4"
                    >Victorian Energy Upgrades.</a
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="large-3 columns">
            <div class="widget_nav_menu">
              <h2 class="block-title">Quick Links</h2>
              <div class="menu-quick-links-container">
                <ul id="menu-quick-links" class="menu">
                  <li
                    id="menu-item-5030"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5030"
                  >
                    <a href="/about">About Us</a>
                  </li>
                  <li
                    id="menu-item-3808"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3808"
                  >
                    <a href="/projects-gallery">Project Gallery</a>
                  </li>
                  <li
                    id="menu-item-3594"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3594"
                  >
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li
                    id="menu-item-5502"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-5502"
                  >
                    <a href="https://smartuser.com.au/customerdetails"
                      >BOOK NOW</a
                    >
                  </li>
                  <li
                    id="menu-item-5661"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5661"
                  >
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li
                    id="menu-item-5949"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-5949"
                  >
                    <a href="/Termsconditions">Terms &#038; Conditions</a>
                  </li>
                  <li
                    id="menu-item-6193"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-6193"
                  >
                    <a href="/TCONLINE">T&#038;C Online</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="large-3 columns">
            <div class="widget_nav_menu">
              <h2 class="block-title">Products &#038; Services</h2>
              <div class="menu-products-services-container">
                <ul id="menu-products-services" class="menu">
                  <li
                    id="menu-item-3597"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3597"
                  >
                    <a href="/solar-panel-system">Solar Panel System</a>
                  </li>
                  <li
                    id="menu-item-5983"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5983"
                  >
                    <a href="/air-conditioning"
                      >Air Conditioners &#038; Rebate (NEW)</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="large-3 columns">
            <div class="widget_contactinfo">
              <h2 class="block-title">Contact</h2>
              <section
                class="block-block contact-details block-block-19 block-even clearfix"
              >
                <ul class="contact-details-list">
                  <li style="display: flex">
                    <i class="fa fa-phone"></i>
                    <div>Phone: (03) 8528 1831</div>
                  </li>
                  <li style="display: flex">
                    <i class="fa fa-envelope-o"></i>
                    <div>
                      Email:
                      <a href="mailto:info@smartuser.com.au"
                        >info@smartuser.com.au</a
                      >
                    </div>
                  </li>
                  <li style="display: flex">
                    <i class="fa fa-map-marker"></i>
                    <div>Address: 11 Howleys Road, Notting Hill VIC 3168</div>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </section>
      </div>
    </section>
    <footer class="l-footer">
      <div class="row p-70">
        <div class="large-4 columns">
          <div class="box padding-top"></div>
        </div>
        <div class="large-4 columns">
          <div class="box text-center logo">
            <img src="../../public/static/Img/logo.png" alt="footer-logo" />
          </div>
        </div>
        <div class="large-4 columns">
          <div class="box padding-top"></div>
        </div>
        <div class="copyright large-12 text-center columns">
          <p>©2024 Smart User. All rights reserved. Powered by Smart User</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
