<template>
  <!-- content  -->
  <main class="l-main" v-title :data-title="$route.name">
    <!-- 轮播图 开始 -->
    <div class="vc_row wpb_row vc_row-fluid">
      <div
        class="wpb_column vc_column_container vc_col-sm-12"
        data-equalizer-watch
      >
        <div class="wpb_wrapper">
          <div
            class="wpb_gallery wpb_content_element vc_clearfix vc_custom_1710907741206"
          >
            <div class="wpb_wrapper">
              <div
                class="wpb_gallery_slides wpb_flexslider flexslider_slide flexslider"
                data-interval="5"
                data-flex_fx="slide"
              >
                <ul class="slides">
                  <li>
                    <a href="/solar-panel-system">
                      <img
                        class=""
                        src="../assets/img/banner1.jpg"
                        width="1920"
                        height="857"
                        alt="32e55b76476222d6b9e2a3749841268"
                        title="32e55b76476222d6b9e2a3749841268"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/air-conditioning">
                      <img
                        class=""
                        src="../assets/img/banner2.jpg"
                        width="1920"
                        height="857"
                        alt="f27ac97d3b84d538fc29b606785e401"
                        title="f27ac97d3b84d538fc29b606785e401"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      data-vc-full-width="true"
      data-vc-full-width-init="false"
      data-vc-stretch-content="true"
      class="vc_row wpb_row vc_row-fluid vc_row-no-padding vc_row-o-equal-height vc_row-flex"
    >
      <div
        class="wpb_column vc_column_container vc_col-sm-3"
        data-equalizer-watch
      >
        <div class="wpb_wrapper">
          <div
            class="revaldak_empty_space"
            data-heightmobile="20"
            data-heighttablet="30"
            data-heightdesktop="30"
          ></div>
        </div>
      </div>
    </div>
    <!-- 轮播图 结束 -->
    <div class="main row">
      <article>
        <div class="body field clearfix">
          <div
            class="wpb_column vc_column_container vc_col-sm-3"
            data-equalizer-watch
          >
            <div class="wpb_wrapper">
              <div
                class="revaldak_empty_space"
                data-heightmobile="20"
                data-heighttablet="40"
                data-heightdesktop="80"
              ></div>
            </div>
          </div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            data-vc-stretch-content="true"
            class="vc_row wpb_row vc_row-fluid vc_row-no-padding vc_row-o-equal-height vc_row-flex"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-3"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="20"
                  data-heighttablet="30"
                  data-heightdesktop="30"
                ></div>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-3"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="wpb_single_image wpb_content_element vc_align_center"
                >
                  <figure class="wpb_wrapper vc_figure">
                    <a
                      href="/air-conditioning"
                      target="_self"
                      class="vc_single_image-wrapper vc_box_border_grey"
                      ><img
                        width="426"
                        height="379"
                        src="../../public/static/Img/mac1.png"
                        class="vc_single_image-img attachment-large"
                        alt=""
                        loading="lazy"
                        srcset="
                          ../../public/static/Img/mac1.png         426w,
                          ../../public/static/Img/mac1-300x267.png 300w
                        "
                        sizes="(max-width: 426px) 100vw, 426px"
                    /></a>
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-3"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="wpb_single_image wpb_content_element vc_align_center"
                >
                  <figure class="wpb_wrapper vc_figure">
                    <a
                      href="/solar-panel-system"
                      target="_self"
                      class="vc_single_image-wrapper vc_box_border_grey"
                      ><img
                        width="426"
                        height="378"
                        src="../../public/static/Img/solar1.png"
                        class="vc_single_image-img attachment-large"
                        alt=""
                        loading="lazy"
                        srcset="
                          ../../public/static/Img/solar1.png         426w,
                          ../../public/static/Img/solar1-300x266.png 300w
                        "
                        sizes="(max-width: 426px) 100vw, 426px"
                    /></a>
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-3"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="20"
                  data-heighttablet="30"
                  data-heightdesktop="30"
                ></div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            class="vc_row wpb_row vc_row-fluid"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-12"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="20"
                  data-heighttablet="40"
                  data-heightdesktop="100"
                ></div>

                <div class="wd-heading text-left">
                  <h2 style="margin: 0; font-size: 35px; color: #020202">
                    Our Processes
                  </h2>

                  <hr style="border-color: #49d0e5; margin: 20px 0" />
                </div>

                <div
                  class="vc_row wpb_row vc_inner vc_row-fluid single-img-logistics"
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="wpb_single_image wpb_content_element vc_align_center single-img-1"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <a
                            href="/Customerdetails"
                            target="_self"
                            class="vc_single_image-wrapper vc_box_border_grey"
                            ><img
                              width="1"
                              height="1"
                              src="https://smartuser.com.au/wp-content/uploads/2016/05/icon1.png"
                              class="vc_single_image-img attachment-thumbnail"
                              alt=""
                              loading="lazy"
                          /></a>
                        </figure>
                      </div>

                      <div
                        class="wpb_raw_code wpb_content_element wpb_raw_html"
                      >
                        <div class="wpb_wrapper">
                          <h3>Book Online/ Call us</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="wpb_single_image wpb_content_element vc_align_center single-img-2"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <div
                            class="vc_single_image-wrapper vc_box_border_grey"
                          >
                            <img
                              width="1"
                              height="1"
                              src="https://smartuser.com.au/wp-content/uploads/2016/05/icon2.png"
                              class="vc_single_image-img attachment-thumbnail"
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        </figure>
                      </div>

                      <div
                        class="wpb_raw_code wpb_content_element wpb_raw_html"
                      >
                        <div class="wpb_wrapper">
                          <h3>Consultation & Make an Appointment</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="wpb_single_image wpb_content_element vc_align_center single-img-3"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <div
                            class="vc_single_image-wrapper vc_box_border_grey"
                          >
                            <img
                              width="1"
                              height="1"
                              src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                              class="vc_single_image-img attachment-thumbnail"
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        </figure>
                      </div>

                      <div
                        class="wpb_raw_code wpb_content_element wpb_raw_html"
                      >
                        <div class="wpb_wrapper">
                          <h3>Installation</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="wpb_single_image wpb_content_element vc_align_center single-img-4"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <div
                            class="vc_single_image-wrapper vc_box_border_grey"
                          >
                            <img
                              width="1"
                              height="1"
                              src="https://smartuser.com.au/wp-content/uploads/2016/05/icon3.png"
                              class="vc_single_image-img attachment-thumbnail"
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        </figure>
                      </div>

                      <div
                        class="wpb_raw_code wpb_content_element wpb_raw_html"
                      >
                        <div class="wpb_wrapper">
                          <h3>Start Saving!</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            class="vc_row wpb_row vc_row-fluid vc_custom_1707953587466 vc_row-has-fill vc_row-o-content-middle vc_row-flex"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-8"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <h2
                  style="font-size: 45px; color: #ffffff; text-align: left"
                  class="vc_custom_heading"
                >
                  <span>Start Saving Today!</span>
                </h2>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-4"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div class="vc_btn3-container vc_btn3-center">
                  <a
                    class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-success"
                    href="/Customerdetails"
                    title="/customerdetails/"
                    target="_blank"
                    >Start Now!</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            data-vc-stretch-content="true"
            class="vc_row wpb_row vc_row-fluid vc_custom_1631694208318 vc_row-has-fill vc_row-o-equal-height vc_row-flex"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-12 vc_custom_1630456489439"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="20"
                  data-heighttablet="40"
                  data-heightdesktop="80"
                ></div>

                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-8"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          About Us
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div class="wpb_text_column wpb_content_element">
                        <div class="wpb_wrapper">
                          <p style="color: #ababab; text-align: center">
                            <span style="color: #333333"
                              >Smart User & Silicon Lighting (VEU Accredited
                              Person) participates in the Victorian Energy
                              Upgrades (VEU) program to deliver energy
                              efficiency improvements which are more affordable
                              for consumers, and reduces their long term energy
                              consumption. Our accreditation can be found at the
                              <a
                                style="color: #333333"
                                href="https://www.veu-registry.vic.gov.au/public/APRegister.aspx?id=401"
                                >Victorian Energy Upgrades</a
                              >. We have helped over 200,000 homes and
                              businesses save energy by using more energy
                              efficient products under VEU program.</span
                            >
                          </p>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="10"
                        data-heighttablet="20"
                        data-heightdesktop="30"
                      ></div>

                      <div class="vc_btn3-container vc_btn3-center">
                        <a
                          class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-flat vc_btn3-color-inverse"
                          href="/about"
                          title="Heat Pump Hotwater System"
                          >FIND OUT MORE</a
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            data-vc-stretch-content="true"
            class="vc_row wpb_row vc_row-fluid vc_custom_1696904726704 vc_row-has-fill vc_row-o-equal-height vc_row-flex"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-2 vc_custom_1600221009335"
              data-equalizer-watch
            >
              <div class="wpb_wrapper"></div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-8"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="wpb_single_image wpb_content_element vc_align_center"
                >
                  <figure class="wpb_wrapper vc_figure">
                    <div class="vc_single_image-wrapper vc_box_border_grey">
                      <img
                        class="vc_single_image-img"
                        src="../../public/static/Img/Num-1.jpg"
                        width="1920"
                        height="203"
                        alt="e2f720332b854489b9b4acb222b1928"
                        title="e2f720332b854489b9b4acb222b1928"
                      />
                    </div>
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-2"
              data-equalizer-watch
            >
              <div class="wpb_wrapper"></div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            class="vc_row wpb_row vc_row-fluid vc_custom_1631697245114 vc_row-has-fill vc_row-o-equal-height vc_row-flex"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-12 vc_custom_1600221009335"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="20"
                  data-heighttablet="40"
                  data-heightdesktop="100"
                ></div>

                <div class="vc_row wpb_row vc_inner vc_row-fluid cols-fright">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-5 vc_custom_1631697778922"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="wpb_single_image wpb_content_element vc_align_left"
                      >
                        <figure class="wpb_wrapper vc_figure">
                          <div
                            class="vc_single_image-wrapper vc_box_border_grey"
                          >
                            <img
                              class="vc_single_image-img"
                              src="../../public/static/Img/311617929768_.pic_hd-300x120.png"
                              width="300"
                              height="120"
                              alt="311617929768_.pic_hd"
                              title="311617929768_.pic_hd"
                            />
                          </div>
                        </figure>
                      </div>
                      <div class="wd-heading text-left">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          $0 Upfront Payment Solar Panel System
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div class="wpb_text_column wpb_content_element">
                        <div class="wpb_wrapper">
                          <p style="color: #ababab">
                            <span style="color: #333333"
                              >Cleaner, Greener Solar System for Everyone. At
                              SmartUser, our mission is to save you big money by
                              making your home as energy efficient as possible.
                              We offer premium solar systems guaranteed to
                              perform in the harsh Australian climate. Get
                              connected with our professional solar expert for
                              an obligation free quote today and place your
                              residential solar power installation project in
                              the hands of a team you can trust.</span
                            >
                          </p>
                        </div>
                      </div>
                      <div class="vc_btn3-container vc_btn3-inline">
                        <a
                          class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-flat vc_btn3-color-inverse"
                          href="/solar-panel-system"
                          title="Heat Pump Hotwater System"
                          >FIND OUT MORE</a
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-7 vc_custom_1631697767976"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="wpb_video_widget wpb_content_element vc_clearfix wpb_animate_when_almost_visible wpb_fadeInLeft fadeInLeft vc_video-aspect-ratio-169 vc_video-el-width-100 vc_video-align-center"
                      >
                        <div class="wpb_wrapper">
                          <div class="wpb_video_wrapper">
                            <iframe
                              title="Smart User Solar Panel Video"
                              width="625"
                              height="352"
                              src="https://www.youtube.com/embed/ZBeKHYbvvB8?feature=oembed"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="revaldak_empty_space"
                  data-heightmobile="0"
                  data-heighttablet="0"
                  data-heightdesktop="100"
                ></div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            class="vc_row wpb_row vc_row-fluid vc_custom_1631706144015 vc_row-has-fill vc_row-o-equal-height vc_row-flex"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-12 vc_custom_1600221009335"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="0"
                  data-heighttablet="0"
                  data-heightdesktop="100"
                ></div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
          <div
            data-vc-full-width="true"
            data-vc-full-width-init="false"
            data-vc-stretch-content="true"
            class="vc_row wpb_row vc_row-fluid vc_row-o-equal-height vc_row-flex"
          >
            <div
              class="wpb_column vc_column_container vc_col-sm-6 vc_hidden-lg vc_hidden-md vc_hidden-sm"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="20"
                  data-heighttablet="40"
                  data-heightdesktop="100"
                ></div>

                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-8"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper">
                      <div class="wd-heading margin_left text-left">
                        <h2 style="margin: 0; font-size: 35px; color: #020202">
                          Solar Panel System
                        </h2>

                        <hr style="border-color: #49d0e5; margin: 20px 0" />
                      </div>

                      <div
                        class="wpb_text_column wpb_content_element margin_left"
                      >
                        <div class="wpb_wrapper">
                          <p style="color: #ababab">
                            <span style="color: #333333"
                              >Cleaner, Greener Solar System for Everyone. At
                              SmartUser, our mission is to save you big money by
                              making your home as energy efficient as possible.
                              We offer premium solar systems guaranteed to
                              perform in the harsh Australian climate. Get
                              connected with our professional solar expert for
                              an obligation free quote today and place your
                              residential solar power installation project in
                              the hands of a team you can trust.</span
                            >
                          </p>
                        </div>
                      </div>
                      <div class="vc_btn3-container vc_btn3-inline">
                        <a
                          class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-flat vc_btn3-color-grey"
                          href="/solar-panel-system"
                          title="Heat Pump Hotwater System"
                          >FIND OUT MORE</a
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>

                <div
                  class="revaldak_empty_space"
                  data-heightmobile="20"
                  data-heighttablet="40"
                  data-heightdesktop="100"
                ></div>
              </div>
            </div>
            <div
              class="wpb_column vc_column_container vc_col-sm-6 vc_hidden-lg vc_hidden-md vc_hidden-sm vc_custom_1606880208669"
              data-equalizer-watch
            >
              <div class="wpb_wrapper">
                <div
                  class="revaldak_empty_space"
                  data-heightmobile="330"
                  data-heighttablet="130px"
                  data-heightdesktop="130px"
                ></div>
              </div>
            </div>
          </div>
          <div class="vc_row-full-width vc_clearfix"></div>
        </div>
      </article>
    </div>
  </main>
  <!-- /content  -->
</template>

<script>
export default {
  computed: {},
};
</script>

<style>
.single-img-logistics:after {
  width: 84%;
  bottom: 60%;
  left: 50%;
  margin-left: -43%;
  margin-bottom: 0px;
}
</style>
